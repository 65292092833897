declare const require: any;
const packageJson = require("../../package.json");

export const environment = {
	production: true,
	appUrl: "https://gronefeld.propeld.eu/",
	apiEndpoint: "https://api.gronefeld.propeld.eu",
	versionNumber: packageJson.version,
	toolbarColor: "accent",
	styling: {
		sideNavPushModeWhen: "screen and (max-width: 1024px)",
		expandToolbarPanelWhen: "screen and (min-width: 1025px)"
	}
};
