import { Directive, Inject, OnDestroy } from "@angular/core";
import { FormGroup } from "@angular/forms";
import {
	BehaviorSubject, ConnectableObservable, Observable, of, ReplaySubject, Subscription, throwError
} from "rxjs";

import { catchError, distinctUntilChanged, publishReplay, switchMap, tap } from "rxjs/operators";
import { Util } from "../../../@twensoc/angular/src/core-module/service";;
import { StoreModel } from "../../../@twensoc/angular/src/core-module/service/resource";
import { MapResource, StoreModelMap } from "../../../@twensoc/angular/src/core-module/service/resource/map";
import { RecordResource } from "../../../@twensoc/angular/src/core-module/service/resource/record";
import { LoggerLocator } from "../../../@twensoc/angular/src/logger-module";
import { FORM_MODEL_FACTORY, FormModelFactory } from "../factory";
import { FormSourceModel } from "../model";
import { FormSource, FormSourceStatus } from "../source";

@Directive()
export abstract class DefaultFormSource implements FormSource, OnDestroy {
	abstract resource: MapResource<StoreModel, StoreModelMap<StoreModel>> | RecordResource<StoreModel>;
	uuid = "";
	protected source: ConnectableObservable<StoreModel>;
	protected sourceSubscription: Subscription;
	protected formSourceModel: FormSourceModel;
	protected formGroupFactoryMethod: () => FormGroup;
	protected formSourceStatus = new BehaviorSubject<FormSourceStatus>(FormSourceStatus.INITIAL);
	protected savedIdSubject = new ReplaySubject<number | string>(1);
	protected idSubject: ReplaySubject<number | string> = new ReplaySubject<number | string>(1);
	protected logger = LoggerLocator.getLogger();

	constructor(@Inject(FORM_MODEL_FACTORY) protected formModelFactory: FormModelFactory) {
		this.uuid = this.uuidv4();
	}

	getForm(): FormGroup {
		return this.formSourceModel.getForm();
	}

	load(id?: number | string | undefined): Observable<StoreModel> {
		if (this.sourceSubscription != null) {
			this.sourceSubscription.unsubscribe();
		}

		this.init();
		this.idSubject.next(id);
		return this.source;
	}

	resetForm(): void {
		this.formSourceModel.reset();
	}

	save(): Observable<number | string | null> {
		return Observable.create(observer => {
			if (this.getForm().valid === false) {
				this.logger.warning("The form wasn't valid", {
					class: DefaultFormSource.name,
					form: this.getForm()
				});
				observer.next(null);
				observer.complete();
				return;
			}

			if (this.getForm().dirty === false) {
				this.logger.warning("The form wasn't dirty", {
					class: DefaultFormSource.name,
					form: this.getForm()
				});
				observer.next(null);
				observer.complete();
				return;
			}

			this.formSourceStatus.next(FormSourceStatus.SAVING);
			const formData: any = this.getForm().value;
			const changes = this.formSourceModel.getDirtyControlValues();

			this.resource.save(formData.id, formData.rev, changes)
				.pipe(switchMap(result => {
					if (Util.isSuccess(result) === false) {
						return throwError(null);
					}

					// noinspection OverlyComplexBooleanExpressionJS
					let id = result && result.response && result.response.payload && result.response.payload.data && result.response.payload.data.id;
					if (id == null) {
						// noinspection OverlyComplexBooleanExpressionJS
						id = result && result.response && result.response.payload && result.response.payload.id;
					}

					if (id != null) this.savedIdSubject.next(id);
					return of(id);
				}), tap(result => {
					this.formSourceStatus.next(FormSourceStatus.IDLE);
					this.formSourceModel.reset();
					this.logger.info("The form has been saved", {
						class: DefaultFormSource.name,
						result: result,
						form: this.getForm()
					});
				}), catchError(error => {
					this.formSourceStatus.next(FormSourceStatus.IDLE);
					this.logger.error("Error within Observable stream", {
						class: DefaultFormSource.name,
						error: error
					});
					return throwError(error);
				}))
				.subscribe(observer);
		});
	}

	setFormGroupFactoryMethod(formGroupFactoryMethod: () => FormGroup): void {
		this.formGroupFactoryMethod = formGroupFactoryMethod;
		this.initFormSourceModel();
	}

	/**
	 * A method which returns the {@see Observable} of {@seet FormSourceStatus} changes. With this
	 * Observable the client can listen for when the form is in a saving / loading / idle or initial state.
	 */
	statusChanged(): Observable<FormSourceStatus> {
		return this.formSourceStatus.asObservable();
	}

	ngOnDestroy(): void {
		if (this.formSourceModel == null) return;
		this.formSourceModel.ngOnDestroy();
		this.formSourceModel = null;

		if (this.sourceSubscription != null) {
			this.sourceSubscription.unsubscribe();
		}
	}

	delete(): Observable<any> {
		return Observable.create(observer => {
			if (!(Util.isMapResource(this.resource))) {
				this.logger.warning(
					"Resources of type RecordResource do not have a delete method, and therefor cannot be deleted",
					{
						class: DefaultFormSource.name,
						form: this.getForm()
					}
				);
				observer.next(null);
				observer.complete();
				return;
			}

			const formData: any = this.getForm().value;
			this.formSourceStatus.next(FormSourceStatus.DELETING);
			// TODO: Upgrade: Explicit casting necessary during upgrade
			const resource = this.resource as MapResource<any, any>;
			resource.delete(formData.id, formData.rev)
				.pipe(switchMap(result => {
					if (Util.isSuccess(result) === false) {
						return throwError(null);
					}

					// noinspection OverlyComplexBooleanExpressionJS
					let id = result && result.response && result.response.payload && result.response.payload.data && result.response.payload.data.id;
					if (id == null) {
						// noinspection OverlyComplexBooleanExpressionJS
						id = result && result.response && result.response.payload && result.response.payload.id;
					}
					return of(id);
				}), tap(result => {
					this.formSourceStatus.next(FormSourceStatus.IDLE);
					this.formSourceModel.reset();
					this.logger.info("The model has been deleted", {
						class: DefaultFormSource.name,
						result: result,
						form: this.getForm()
					});
				}), catchError(error => {
					this.formSourceStatus.next(FormSourceStatus.IDLE);
					this.logger.error("Error within Observable stream", {
						class: DefaultFormSource.name,
						error: error
					});
					return throwError(error);
				}))
				.subscribe(observer);
		});
	}

	savedId(): Observable<number | string> {
		return this.savedIdSubject.asObservable();
	}

	protected initFormSourceModel(): void {
		if (this.formSourceModel != null) {
			this.formSourceModel.ngOnDestroy();
			this.formSourceModel = null;
		}
		this.formSourceModel = this.formModelFactory.create(this.formGroupFactoryMethod());
	}

	protected init(): void {
		this.source = <ConnectableObservable<StoreModel>>this.idSubject.pipe(distinctUntilChanged(),
			tap(id => {
				this.initFormSourceModel();
				this.formSourceStatus.next(FormSourceStatus.LOADING);
				this.logger.debug("Id changed", {
					class: DefaultFormSource.name,
					id: id
				});
			}),
			switchMap(id => {
				if (Util.isMapResource(this.resource)) {
					// TODO: Upgrade: Explicit casting necessary during upgrade
					const resource = this.resource as MapResource<any, any>
					if ((typeof id === "number" && id !== 0) || (typeof id === "string" && id.length > 0)) {
						return resource.getById(<any>id);
					}
					return resource.getPristineModel();
				}
				return this.resource.get();
			}),
			tap(model => {
				this.formSourceModel.update(model);
				this.formSourceStatus.next(FormSourceStatus.IDLE);
				this.logger.debug("Model has been updated", {
					class: DefaultFormSource.name,
					model: model
				});
			}),
			publishReplay()
		);
		this.sourceSubscription = this.source.connect();
	}

	private uuidv4() {
		return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
			var r = Math.random() * 16 | 0,
				v = c == "x" ? r : (r & 0x3 | 0x8);
			return v.toString(16);
		});
	}
}
