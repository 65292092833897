import { Injectable } from "@angular/core";
import { Action, Store } from "@ngrx/store";
import { filter } from "rxjs/operators";

import { VertXSocket, VertXSocketMessage } from "./socket/vertx";

@Injectable()
export class Dispatcher {

	constructor(protected store: Store<any>, protected socket: VertXSocket) {
		this.dispatchAction();
	}

	dispatchAction(): void {
		this.socket.messages()
			.pipe(
				filter((value: VertXSocketMessage) =>
					(value != null && value.type != null &&
						(value.payload != null)))
			)
			.subscribe(value =>
				this.store.dispatch(<Action>value),
					error => console.error(error));
	}
}
