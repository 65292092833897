import { Action, Store } from "@ngrx/store";
import { Observable, of as observableOf, throwError as observableThrowError } from "rxjs";

import { catchError, switchMap, tap } from "rxjs/operators";

import { VertXSocket, VertXSocketMessageResponse } from "../../socket";
import { TsAppActions } from "../actions";
import { Model } from "../model";
import { Resource } from "../resource";

export abstract class RecordResource<M extends Model> extends Resource<M> {

	constructor(
		protected socket: VertXSocket,
		protected store: Store<any>,
		protected subStoreName: string,
		protected actionPrefix: string
	) {
		super(store, subStoreName, actionPrefix);
	}

	/**
	 * Function through which a model can be created / saved. Based on the id (if this is equal to 0 a new model is created else it's updated)
	 * This function returns a very simple result that indicates of the call save went successful or if it failed.
	 *
	 * Example:
	 * @Component(...)
	 * export class TemplateComponent {
	 * 	constructor(public resource: MapResource) {}
	 * 
	 * 	submit() {
	 * 	  this.resource.save(0, 0, {name: 'Nick'}).subscribe(
	 * 		(response: {success: boolean, response: any}) => {
	 *		  //handle success 
	 * 	  	}, (error: {success: boolean, error: any, request: Action}) => {
	 * 		  //handle errors
	 * 	  	}
	 * 	  )
	 * 	}
	 * }
	 * Error result: { success: boolean, error: any, request: Action }
	 */
	save(id: string, revision: number, changes: any): Observable<VertXSocketMessageResponse>;
	save(id: number, revision: number, changes: any): Observable<VertXSocketMessageResponse>;
	save(id: any, revision: number, changes: any): Observable<VertXSocketMessageResponse> {
		return this.update(id, revision, changes).pipe(catchError(error => {
			console.error(error);
			if (error.request != null) this.dispatchError(error.request, error.error);
			return observableThrowError(error);
		}));
	}

	/**
	 * Function that handles the update action when the id is not equal to 0 either greater or smaller. It simply calls the server and passes along the data to the server.
	 * Which results in an simple yet clear result that indicates if the call went successful or if it failed.
	 * This method generally gets called by {@link MapResource.save(1, ...)} / {@link MapResource.save(-1, ...)}
	 * Error result: { success: boolean, error: any, request: Action }
	 */
	protected update(id: any, revision: number, changes: any): Observable<VertXSocketMessageResponse> {
		let action = {
			type: this.actionPrefix + "/update",
			payload: {
				id: id,
				rev: revision,
				data: changes
			}
		};
		return observableOf(action).pipe(
			tap(action => this.store.dispatch(action)),
			switchMap(action => this.socket.emit(action.type, action))
		);
	}

	/**
	 * Function that dispatches an event that can be handled by another part of the application.
	 * If a reducer picks up this specific type. For example this could be used to add error messages so that they could be
	 * displayed as toast messages to the user.
	 */
	protected dispatchError(action: Action, response: any) {
		this.store.dispatch({
			type: TsAppActions.ERROR_REPORT,
			payload: {
				send: action,
				response: response
			}
		});
	}
}
