import { Inject } from "@angular/core";
import { DefaultFormSource, FORM_MODEL_FACTORY, FORM_SOURCE, FormModelFactory } from "../form-source";
import { COMPANY_FORM_GROUP_FACTORY } from "./model";
import { CompanyResource } from "./resource";

export function companyFormSourceFactory(companyResource: CompanyResource,
	formModelFactory: FormModelFactory
) {
	return new CompanyFormSource(companyResource, formModelFactory);
}

export const COMPANY_FORM_SOURCE_PROVIDER = {
	provide: FORM_SOURCE,
	useFactory: companyFormSourceFactory,
	deps: [CompanyResource, FORM_MODEL_FACTORY]
};

export class CompanyFormSource extends DefaultFormSource {
	constructor(public resource: CompanyResource,
		@Inject(FORM_MODEL_FACTORY) formModelFactory: FormModelFactory
	) {
		super(formModelFactory);
		this.setFormGroupFactoryMethod(COMPANY_FORM_GROUP_FACTORY);
	}
}