import { OnDestroy } from "@angular/core";
import { StoreModel } from "../../@twensoc/angular/src/core-module/service/resource";
import { DefaultFormSourceModel } from "../form-source";

export class UserFormSourceModel extends DefaultFormSourceModel implements OnDestroy {

	update(model: StoreModel) {
		super.update(model);
		if (this.getForm().get("id").value > 0) {
			this.getForm().get("email").disable();
		}
	}

}
