import { FormGroup } from "@angular/forms";
import { FormModelFactory } from "../factory";
import { FormSourceModel } from "../model";
import { DefaultFormSourceModel } from "./model";
import { Injectable } from "@angular/core";

@Injectable()
export class DefaultFormModelFactory implements FormModelFactory {
	create(formGroup: FormGroup): FormSourceModel {
		return new DefaultFormSourceModel(formGroup);
	}

}