import { QueryFilter } from "../../@twensoc/angular/src/core-module/service/resource";
import { Operator } from "../operator";

export enum SortDirection {
	DEFAULT = "", ASC = "ASC", DESC = "DESC"
}

export enum FieldCast {
	JSON = "JSONB", INT = "integer"
}

export interface FieldFilter {
	field: string;
	value: any;
	cast?: FieldCast;
}

export interface Sort {
	field: string;
	direction: SortDirection
}

export interface ListFilter {
	getOperator(): Operator;

	setOperator(operator: Operator): ListFilter;

	/**
	 * @deprecated please use {@see getOperator} instead
	 */
	getFieldFilters(): FieldFilter[]

	getPreDeterminedFilter(): string

	getSort(): Sort | null

	getLimit(): number

	getPageNumber(): number

	/**
	 * @deprecated please use {@see getOperator} instead
	 */
	getSearch(): string

	/**
	 * @deprecated please use {@see setOperator} instead
	 */
	setSearch(string: string): ListFilter

	/**
	 * @deprecated please use {@see setOperator} instead
	 */
	clearSearch(): ListFilter

	setPageNumber(pageNo: number): ListFilter

	setOffset(offset: number): ListFilter

	setLimit(limit: number): ListFilter

	setSort(fieldName: string, direction: SortDirection): ListFilter

	clearSort(): ListFilter

	/**
	 * @deprecated please use {@see setOperator} instead
	 */
	addFieldFilter(fieldName: string, value: any, cast?: FieldCast): ListFilter

	/**
	 * @deprecated please use {@see setOperator} instead
	 */
	removeFieldFilter(fieldName: string, value: any): ListFilter

	setPreDeterminedFilter(name: string): ListFilter

	clearPreDeterminedFilter(): ListFilter

	build(): QueryFilter
}

