import { ErrorHandler, Injectable, Injector, NgZone } from "@angular/core";
import { ToastMessageResource } from "../../@twensoc/angular/src/core-module/service/resource/toast-message";
import { LoggerLocator } from "../../@twensoc/angular/src/logger-module";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
	private logger = LoggerLocator.getLogger();

	constructor(private injector: Injector, private ngZone: NgZone) {
	}

	handleError(error: Error) {
		const message = error.message ? error.message : error.toString();
		this.logger.fatal(message, error);

		const date = (new Date()).toISOString();
		const toastMessageResource = this.injector.get(ToastMessageResource);
		toastMessageResource.add(`${date} Er is een fout opgetreden`, "ERROR", null, {
			label: "Probeer het opnieuw",
			action: () => {
				/* istanbul ignore next: Impossible to mock sadly and angular doesn't provide a way to do
				 this through a service. The only real option is creating a service your own but that
				  couldn't be tested either, so this sadly has to do for now. */
				window.location.reload();
			}
		});
	}
}
