import { ModuleWithProviders, NgModule } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { PageTitleService } from "./service";

@NgModule()
export class PageTitleCoreModule {
	static forRoot(): ModuleWithProviders<PageTitleCoreModule> {
		return {
			ngModule: PageTitleCoreModule,
			providers: [
				Title, PageTitleService
			]
		};
	}
}
