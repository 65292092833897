import { ModuleWithProviders } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoginGuard } from "./core/guard";

export const appRoutes: Routes = [
	{
		path: "login",
		canActivate: [LoginGuard],
		loadChildren: () => import("./route/login/module").then(m => m.LoginRouteModule)
	}, {
		path: "request-password-reset",
		loadChildren: () => import("./route/password-reset/module").then(m => m.PasswordResetRequestRouteModule)
	}, {
		path: "t/:token",
		loadChildren: () => import("./route/action-token/module").then(m => m.ActionTokenRouteModule)
	}, {
		path: "",
		loadChildren: () => import("./route/base/module").then(m => m.BaseRouteModule)
	}, {
		path: "**",
		loadChildren: () => import("./route/404/module").then(m => m.PageNotFoundRouteModule)
	}
];
export const routingModule: ModuleWithProviders<RouterModule>  = RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' });
