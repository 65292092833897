import { Injectable, InjectionToken } from "@angular/core";
import { AggregateListSourceConfig } from "./model";

export const AGGREGATE_LIST_CONFIG_FACTORY = new InjectionToken<AggregateListSourceConfigFactory>(
	"AggregateListSourceConfigFactory");

@Injectable()
export class AggregateListSourceConfigFactory {
	create(mainSourcePropertyName: string): AggregateListSourceConfig {
		return new AggregateListSourceConfig(mainSourcePropertyName);
	}
}