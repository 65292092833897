import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { ConsoleHandler, LoggerLocator } from "./app/@twensoc/angular/src/logger-module";
import { DefaultLogger } from "./app/@twensoc/angular/src/logger-module/logger/logger";

import { Module } from "./app/module";
import { environment } from "./environments/environment";

if (environment.production) {
	enableProdMode();
}

// provide logger
const logger = new DefaultLogger();
logger.addHandler(new ConsoleHandler());
LoggerLocator.provide(logger);

platformBrowserDynamic().bootstrapModule(Module)
	.catch(error => {
		const message = error.message ? error.message : error.toString();
		logger.fatal(message, error);
	});
