import { Map, Record } from "immutable";
import { StoreModel } from "../model";

export interface TsFilesStore extends Map<number, TsFile> {
}

export const initialTsFilesStore: TsFilesStore = Map<number, TsFile>();

export interface TsFileModel extends StoreModel {
	entityPrefix: string;
	refId: number;
	fileSeq: number;
	fileSeqRev: number;
	group: string;
	name: string;
	ext: string;
	derived: string;
	userId: number;
	oName: string;
	contentType: string;
	charSet: string;
	fileSize: number;
	timestamp: string;
	physicalFileDeleted: boolean;
}

export const TsFileRecord = Record({
	id: 0,
	rev: 0,
	entityPrefix: "",
	refId: 0,
	fileSeq: 0,
	fileSeqRev: 0,
	group: "",
	name: "",
	ext: "",
	derived: "",
	userId: 0,
	oName: "",
	contentType: "",
	charSet: "",
	fileSize: 0,
	timestamp: "",
	physicalFileDeleted: false
});

export class TsFile extends TsFileRecord implements TsFileModel {
	id: number;
	rev: number;
	entityPrefix: string;
	refId: number;
	fileSeq: number;
	fileSeqRev: number;
	group: string;
	name: string;
	ext: string;
	derived: string;
	userId: number;
	oName: string;
	contentType: string;
	charSet: string;
	fileSize: number;
	timestamp: string;
	physicalFileDeleted: boolean;

	get displayName(): string {
		return `${this.oName} - V${this.rev}`;
	}

	get filePath(): string {
		return `/${this.entityPrefix}-file/${this.id}`;
	}

	get actionPrefix(): string {
		return `${this.entityPrefix}-file`;
	}

	/**
	 * Used image regex: {@link https://regex101.com/r/LJjMx1/1}
	 */
	// getThumbnail(width: number, height: number): string {
	// 	const imgRegex = /^image\/([a-zA-Z].*)/;
	// 	return (imgRegex.test(this.contentType) ? `${this.filePath}/${width}-${height}` : '');
	// }
}