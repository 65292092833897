import { ModuleWithProviders, NgModule } from "@angular/core";
import { Dispatcher } from "./service";
import { TsFileResource } from "./service/resource/file";
import { ToastMessageResource } from "./service/resource/toast-message";

@NgModule({})
export class TsCoreModule {
	static forRoot(): ModuleWithProviders<TsCoreModule> {
		return {
			ngModule: TsCoreModule,
			providers: [
				ToastMessageResource, TsFileResource, Dispatcher
			]
		};
	}
}