import { LayoutModule } from "@angular/cdk/layout";
import { registerLocaleData } from "@angular/common";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import localeNL from "@angular/common/locales/nl";
import { LOCALE_ID, ModuleWithProviders, NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Router } from "@angular/router";
import { ActionReducerMap, INITIAL_REDUCERS, Store, StoreModule, StoreRootModule } from "@ngrx/store";
import { environment } from "../environments/environment";
import { TsCoreModule } from "./@twensoc/angular/src/core-module/module";
import { Authentication } from "./@twensoc/angular/src/core-module/service";
import { toastMessages } from "./@twensoc/angular/src/core-module/service/resource/toast-message";
import { SocketOptions, SockJSBackend, VertXSocket } from "./@twensoc/angular/src/core-module/service/socket";
import { AppComponent } from "./component";
import { AccountCoreModule } from "./core/account/module";
import { ErrorHandlingCoreModule } from "./core/error-handling/module";
import { GuardCoreModule } from "./core/guard/module";
import { PageTitleCoreModule } from "./core/page-title/module";
import { TranslatorCoreModule } from "./core/translator/module";
import { UIStateCoreModule } from "./core/user-interface/module";
import { routingModule } from "./route";

registerLocaleData(localeNL, "nl");

// todo move to separate file
// noinspection TsLint
interface ApplicationState {
}

const reducers: ActionReducerMap<ApplicationState> = {
	toastMessages: toastMessages
};
const applicationStore: ModuleWithProviders<StoreRootModule>  = StoreModule.forRoot(INITIAL_REDUCERS);

// todo move to separate file
export function authenticationProvideFactory(http: HttpClient,
	socket: VertXSocket,
	router: Router,
	store: Store<any>
) {
	const authentication = new Authentication({url: environment.apiEndpoint}, http, socket, router, store);
	authentication.initialize();
	return authentication;
}

// todo move to seperate file
export function vertXProvideFactory() {
	const socketOptions: SocketOptions = {
		url: environment.apiEndpoint + "/eventbus",
		replyAddressKey: "replyAddress",
		responseAddressKey: "address"
	};
	return new VertXSocket(socketOptions, new SockJSBackend());
}

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		HttpClientModule,
		ReactiveFormsModule,
		ReactiveFormsModule,
		FormsModule,
		BrowserModule,
		BrowserAnimationsModule,
		MatSnackBarModule,
		FlexLayoutModule,
		LayoutModule,
		TsCoreModule.forRoot(),
		ErrorHandlingCoreModule.forRoot(),
		routingModule,
		applicationStore,
		UIStateCoreModule,
		AccountCoreModule.forRoot(),
		GuardCoreModule.forRoot(),
		TranslatorCoreModule.forRoot(),
		PageTitleCoreModule.forRoot()
	],
	providers: [
		{
			provide: VertXSocket,
			useFactory: vertXProvideFactory
		}, {
			provide: INITIAL_REDUCERS,
			useValue: reducers
		}, {
			provide: Authentication,
			useFactory: authenticationProvideFactory,
			deps: [HttpClient, VertXSocket, Router, Store]
		}, {
			provide: LOCALE_ID,
			useValue: "nl"
		}
	],
	bootstrap: [AppComponent]
})
export class Module {
}
