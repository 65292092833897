/**
 * Created by Raoni on 14-8-2017.
 */
import { Injectable } from "@angular/core";

export interface TsTranslateMessage {
	type: string;
	message: string;
	errorCode?: number;
}

export interface TsTranslateResponse {
	message: string;
	severity?: "DEFAULT" | "WARNING" | "ERROR" | "SUCCESS";
}

@Injectable()
export class TsTranslator {

	/**
	 * function that translates an incoming message to a string that the user can understand.
	 * Needs to be overwritten in the application!
	 * @param {String} message
	 */
	translateMessage(message: TsTranslateMessage): TsTranslateResponse {
		console.warn("Translator not overwritten in application!");
		return {
			message: "implement in application",
			severity: "ERROR"
		};
	}

}