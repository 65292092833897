/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 07/11/2017
 * Time: 14:11
 * For Project: angular-core
 */
import {
	AbstractControl, AsyncValidatorFn, FormArray, FormControl, FormGroup, // UntypedFormArray,
	// UntypedFormControl,
	// UntypedFormGroup,
	ValidatorFn
} from "@angular/forms";
import { StoreModel } from "./resource";

// TODO: Reset to Untyped!!!
// export type FormArrayControlFactory = (data?: any) => UntypedFormGroup | UntypedFormControl;
export type FormArrayControlFactory = (data?: any) => FormGroup | FormControl;

export class TsFormArray extends FormArray {
	constructor(
		private factory: FormArrayControlFactory,
		validator?: ValidatorFn,
		asyncValidator?: AsyncValidatorFn
	) {
		super([], validator, asyncValidator);
	}

	/**
	 * Method overloaded from {@link FormArray#push} with it's parameters removed
	 * As the user has no freedom to dictate which controls or groups are added here.
	 */
	push(): void {
		super.push(this.factory());
	}

	/**
	 * The setControl method shouldn't be used within the context of the TsFormArray.
	 * This is directly manipulating an existing control which is out of scope for the TsFormArray.
	 * In such a case where this is desired please use the default {@link FormArray}
	 * @throws Error
	 */
	setControl(index?: any, control?: any) {
		throw new Error("[TsFormArray#setControl] METHOD IS NOT ALLOWED TO BE CALLED");
	}

	/**
	 * Function that adds the default factory created item at the specified index.
	 * It also passes the model of the to be added data structure.
	 * This can be useful in the situation where you want to create different {@link FormGroup} or {@link FormControl} based on the models
	 * A situation where this might be useful is if you have an array of animals and the form for dogs is different from the form for cats.
	 *
	 * Insert overload is needed otherwise the project can not compile because of strict extend checking of newer typescript versions.
	 */
	insert(index: number, control?: AbstractControl): void
	insert<M extends StoreModel>(index: number, model?: M): void {
		if (arguments[1] instanceof AbstractControl) throw "[TsFormArray#insert] Method can not be called with a control please use FormArray instead!";
		if (this.at(index) != null) return;
		super.insert(index, this.factory(model));
	}
}
