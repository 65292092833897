import { ActionReducer } from "@ngrx/store";
import { TsAppActions } from "../actions";
import { initialToastMessagesStore, ToastMessage, ToastMessagesStore } from "./model";

export const toastMessages: ActionReducer<ToastMessagesStore> = (state: ToastMessagesStore = initialToastMessagesStore,
	action: { payload: any, type: string }
) => {
	switch (action.type) {
		case TsAppActions.TOAST_ADD:
			return state.withMutations(list => {
				let toastMessage = new ToastMessage(action.payload);
				if (toastMessage.content == null || toastMessage.content.length <= 0) {
					console.error("Their was no toastMessage content within the action.payload");
					return state;
				}
				list.push(toastMessage);
			});
		case TsAppActions.TOAST_SHIFT:
			return state.shift();

		case TsAppActions.ERROR_REPORT:
			return state.withMutations(list => list.push(new ToastMessage({
				content: action.payload.send.type + " failed!",
				type: "ERROR",
				translateMessage: {
					type: action.payload.send.type,
					message: action.payload.response.message,
					errorCode: action.payload.response.failureCode
				}
			})));

		default:
			return state;
	}
};