import { ActionConverter, ReducerHandlers, TsAppActions } from "../../@twensoc/angular/src/core-module/service/resource";
import { UserActions } from "./action";
import { initialUserStore, User, UserConversion, UserStore } from "./model";

export function users(state: UserStore = initialUserStore, action: { type: string, payload: any }) {
	switch (action.type) {
		case UserActions.RECEIVED:
			return ReducerHandlers.handleReceivedAction(state,
				ActionConverter.adapt(action, UserConversion),
				new User()
			);

		case UserActions.CREATE:
			return ReducerHandlers.handleCreateAction(state,
				ActionConverter.adapt(action, UserConversion),
				new User()
			);

		case UserActions.CREATED:
			return ReducerHandlers.handleCreatedAction(state,
				ActionConverter.adapt(action, UserConversion),
				new User()
			);

		case UserActions.UPDATED:
			return ReducerHandlers.handleUpdatedAction(state,
				ActionConverter.adapt(action, UserConversion),
				new User()
			);

		case TsAppActions.ACCOUNT_CHANGED:
			return initialUserStore;

		default:
			return state;
	}
}
