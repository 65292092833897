import { Injectable } from "@angular/core";
import {
	TsTranslateMessage, TsTranslateResponse,
	TsTranslator
} from "../../@twensoc/angular/src/core-module/service";

@Injectable()
export class AppTranslator extends TsTranslator {
	translateMessage(message: TsTranslateMessage): TsTranslateResponse {
		if (message.message.startsWith("{") && message.message.endsWith("}")) {
			const content = JSON.parse(message.message);
			const failureText = content && content.data && content.data.failure && content.data.failure.text;

			if (failureText != null && failureText.length > 0) {
				return {
					message: failureText,
					severity: "ERROR"
				};
			}
		}

		return {
			message: `[${message.type}]: ${message.message}`,
			severity: "ERROR"
		};
	}
}
