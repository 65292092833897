import { HttpClientModule } from "@angular/common/http";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { TsCoreModule } from "../../@twensoc/angular/src/core-module/module";
import { account } from "./reducer";
import { AccountResource } from "./resource";

@NgModule({
	imports: [
		StoreModule.forFeature("account", account), HttpClientModule, TsCoreModule
	]
})
export class AccountCoreModule {
	static forRoot(): ModuleWithProviders<AccountCoreModule> {
		return {
			ngModule: AccountCoreModule,
			providers: [AccountResource]
		};
	}
}
