import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { uiState } from "./reducer";

@NgModule({
	imports: [
		StoreModule.forFeature("uiState", uiState)
	]
})
export class UIStateCoreModule {
}