import { InjectionToken } from "@angular/core";
import { OrderedMap } from "immutable";
import { Observable } from "rxjs";
import { StoreModel } from "../../@twensoc/angular/src/core-module/service/resource";
import { ListFilter } from "./model";

export const LIST_SOURCE = new InjectionToken<ListSource>("ListSource");

export interface ListSource {
	applyFilter(filter: ListFilter): void;

	refresh(): void;

	connect(): Observable<StoreModel[]>

	disconnect(): any

	changedFilter(): Observable<ListFilter>

	getById(id: number | string): Observable<StoreModel>

	getByIds(ids: number[] | string[]): Observable<OrderedMap<string|number, StoreModel>>

	count(): Observable<number>
}
