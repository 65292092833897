import { List, Record } from "immutable";
import { environment } from "../../../environments/environment";
import { StoreModel } from "../../@twensoc/angular/src/core-module/service/resource";
import { loggedIn } from "../../@twensoc/angular/src/core-module/service";

export enum Role {
	GUEST = "GUEST", CUSTOMER = "CUSTOMER", USER = "USER", CADMIN = "CADMIN", ADMIN = "ADMIN"
}


export enum AuthRoleId {
	GUEST = 1,
	CUSTOMER = 2,
	USER = 3,
	CADMIN = 4,
	ADMIN = 5
}

export function getAuthRoleId(value: Role): number {
	switch (value) {
		case Role.GUEST:
			return AuthRoleId.GUEST;
		case Role.CUSTOMER:
			return AuthRoleId.CUSTOMER;
		case Role.USER:
			return AuthRoleId.USER;
		case Role.CADMIN:
			return AuthRoleId.CADMIN;
		case Role.ADMIN:
			return AuthRoleId.ADMIN;
		default:
			return AuthRoleId.GUEST;
	}
}

export interface AccountModel extends StoreModel {
	initials: string;
	firstName: string;
	infix: string;
	surname: string;
	gender: string;
	email: string | null;
	phone: string;
	role: Role;
	active: true;
	ppId: number | null;
	parentId: number | null;
	companyName: string;
	nodeType: string;
	locale: string;
	loggedIn: string;
	wasAdmin: boolean;
	permissions: List<String>;
}

export class Account extends Record({
	id: 0,
	rev: 0,
	initials: "",
	firstName: "",
	infix: "",
	surname: "",
	gender: "",
	email: "",
	phone: "",
	authRole: Role.GUEST,
	authRoleId: AuthRoleId.GUEST,
	role: Role.GUEST, // TODO: Remove
	active: true,
	ppId: null,
	parentId: null,
	companyName: "",
	nodeType: "",
	locale: "nl_NL",
	loggedIn: loggedIn.DEFAULT,
	wasAdmin: false,
	companyIds: "",
	permissions: List()
}) implements AccountModel {
	id: number;
	rev: number;
	initials: string;
	firstName: string;
	infix: string;
	surname: string;
	gender: string;
	email: string;
	phone: string;
	role: Role;
	active: true;
	ppId: number | null;
	parentId: number | null;
	companyName: string;
	nodeType: string;
	locale: string;
	loggedIn: string;
	wasAdmin: boolean;
	permissions: List<String>;

	get displayName(): string {
		const v = [];
		if (this.firstName != null && this.firstName !== "") v.push(this.firstName);
		if (this.infix != null && this.infix !== "") v.push(this.infix);
		if (this.surname != null && this.surname !== "") v.push(this.surname);
		return v.join(" ");
	};

	get profilePictureSrc(): string {
		if (this.ppId == null || this.ppId === 0) {
			return `/assets/images/profile.jpg`;
		} else {
			return `${environment.apiEndpoint}/user-file/${this.ppId}/100x100`;
		}
	}

	get isLoggedIn(): boolean {
		return (this.id > 0 && this.loggedIn === loggedIn.TRUE);
	}

	hasPermission(name: string): boolean {
		return this.permissions != null && this.permissions.indexOf(name) !== -1;
	}
}

export const initialAccount: Account = new Account({});
