import { HttpClient, HttpEventType, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Action, Store } from "@ngrx/store";
import { OrderedMap } from "immutable";
import { BehaviorSubject, Observable, of as observableOf, throwError as observableThrowError } from "rxjs";

import { catchError, map, switchMap, tap } from "rxjs/operators";

import { VertXSocket, VertXSocketMessageResponse } from "../../socket";
import { MapResource } from "../map";
import { QueryFilter } from "../query-filter";
import { ToastMessageResource } from "../toast-message";
import { TsFile, TsFilesStore } from "./model";

export enum ProcessingState {
	IDLE, PROCESSING
}

export enum TsUploadFileState {
	QUEUED, UPLOADING, DONE, REJECTED
}

export class TsUploadFile {
	file: File;
	state: TsUploadFileState = TsUploadFileState.QUEUED;
	progress: BehaviorSubject<number> = new BehaviorSubject(0);
	uUiId: string;
	params: { [key: string]: string };
	url: string;

	constructor(file: File, uUiId: string, params: { [key: string]: string }, url: string) {
		this.file = file;
		this.uUiId = uUiId;
		this.params = params;
		this.url = url;
	}

}

@Injectable()
export class TsFileResource extends MapResource<TsFile, TsFilesStore> {
	private processingState: ProcessingState = ProcessingState.IDLE;
	private fileQueue: TsUploadFile[] = [];
	private queueSubject = new BehaviorSubject<TsUploadFile[]>([]);

	constructor(socket: VertXSocket,
		store: Store<TsFilesStore>,
		private httpClient: HttpClient,
		private toastMessageResource: ToastMessageResource
	) {
		super(socket, store, "files", "file", new TsFile());
	}

	/**
	 * Quick access method for the {@link MapResource#filter} method. This functions takes the parameters
	 * {@param entityPrefix} + {@param refId} + {@param group} (optionally) and adds those to the
	 * {@link QueryFilter#filters} property.
	 */
	find(entityPrefix: string,
		refId: number,
		actionPrefix: string,
		group?: string
	): Observable<OrderedMap<number, TsFile>> {
		const filter = {
			offset: 0,
			limit: 1000,
			search: "",
			filters: [
				{
					field: "entityPrefix",
					value: entityPrefix
				}, {
					field: "refId",
					value: refId
				}
			],
			sorting: [],
			name: ""
		};
		if (group != null) {
			filter.filters.push({
				field: "group",
				value: group
			});
		}
		return this.filter(filter, null, actionPrefix);
	}

	/**
	 * Overwritten method {@link MapResource#save} this function prevents clients using it
	 * as the file can never be create (d) and update (d) from within the client through the websocket
	 * layer.
	 * Todo: in the future this method should be the method to form the XHR POST and PUT calls used by any upload component like {@link TsUploadComponent}
	 */
	save(id: string, revision: number, changes: any): Observable<VertXSocketMessageResponse>;
	save(id: number, revision: number, changes: any): Observable<VertXSocketMessageResponse>;
	save(id: any, revision: number, changes: any): Observable<VertXSocketMessageResponse> {
		throw new Error(
			"[TsFileResource#save] this method shouldn't be called, please use the appropriate TsUploadComponent instead");
	}

	// noinspection ReservedWordAsName
	/**
	 * See {@link MapResource#delete}
	 */
	delete(id: string, revision: number, actionPrefix?: string): Observable<VertXSocketMessageResponse>;
	delete(id: number, revision: number, actionPrefix?: string): Observable<VertXSocketMessageResponse>;
	delete(id: any, revision: number, actionPrefix?: string): Observable<VertXSocketMessageResponse>;
	delete(id: any, revision: number): Observable<VertXSocketMessageResponse> {
		const prefix = arguments[2];
		if (prefix == null) throw new ReferenceError("[TsFileResource#delete] actionPrefix not defined");

		if (typeof prefix === "string" && typeof id === "number") {
			return this.doDeleteRequest(id, revision, prefix).pipe(//handle error that occur
				catchError(error => {
					console.error(error);
					if (error.request != null) this.dispatchError(error.request, error.error);
					return observableThrowError(error);
				}));
		}
	}

	/**
	 * see {@link MapResource#filter}
	 */
	filter(filter: QueryFilter, uUiId: string, actionPrefix?: string): Observable<OrderedMap<number, TsFile>>;

	filter(filter: QueryFilter, uUiId?: string): Observable<OrderedMap<number, TsFile>> {
		const actionPrefix = arguments[2];
		if (actionPrefix == null) throw new ReferenceError("[TsFileResource#filter] actionPrefix not defined");

		return <Observable<OrderedMap<number, TsFile>>>observableOf(filter).pipe(switchMap(
			filter => this.doFilterRequest(filter, actionPrefix)),
			tap(result => (uUiId != null)
				? this.dispatchFilterCount(uUiId + ".count", result.response.payload.count) : null),
			map(result => result.response.payload.items.map(value => value.id)),
			switchMap(ids => this.getByIds(ids, actionPrefix)),
			catchError(error => {
				console.error(error);
				if (error.request != null) this.dispatchError(error.request, error.error);
				return observableOf(OrderedMap()); // return empty OrderedMap in case of error
			})
		);
	}

	/**
	 * see {@link MapResource#getByIds}
	 */
	getByIds(ids: string[], actionPrefix?: string): Observable<OrderedMap<string, TsFile>>;

	getByIds(ids: number[], actionPrefix?: string): Observable<OrderedMap<number, TsFile>>;

	getByIds(ids: any[], actionPrefix?: string): Observable<OrderedMap<any, TsFile>> {
		return this.get().pipe(map(map => ids.filter(id => map.has(id) === false)),
			switchMap(idsToBeRetrieved => {
				if (idsToBeRetrieved.length <= 0) return observableOf({success: true});
				return this.doGetRequest(idsToBeRetrieved, actionPrefix);
			}),
			switchMap(result => this.get()),
			map(map => {
				let availableIds = ids.filter(id => (map.has(id) === true));
				return availableIds.reduce((previousValue, id) => previousValue.set(id, map.get(id)),
					OrderedMap()
				);
			}),
			catchError(error => {
				console.error(error);
				if (error.request != null) this.dispatchError(error.request, error.error);
				return observableOf(OrderedMap()); // return empty OrderedMap in case of error
			})
		);
	}

	getQueuedFiles(uUiId?: string): Observable<TsUploadFile[]> {
		return this.queueSubject.pipe(map(queue => {
			if (uUiId == null) {
				return queue;
			} else {
				return queue.filter(uploadFile => uploadFile.uUiId === uUiId);
			}
		}), catchError(error => {
			console.error(error);
			if (error.request != null) this.dispatchError(error.request, error.error);
			return observableOf([]); // return empty Array in case of error
		}));
	}

	/**
	 * Function to add files to the queue.
	 * It goes trough every file of the FileList or Array.
	 * If a array of MimeTypes is given it first checks if the type of the file is accepted with the {@link isFileValid} function
	 * if a file is valid it is then added to the queue.
	 * When it is done adding all the files and the current processingState is idle it calls the {@link processQueue} function
	 * so it can empty the queue.
	 */
	uploadFiles(files: FileList | File[],
		params: { [key: string]: string },
		url: string,
		uUiId: string = "",
		acceptedMimeTypes?: string[]
	): void {
		for (let i = 0; i < files.length; i++) {
			if (acceptedMimeTypes == null || this.isFileValid(files[i], acceptedMimeTypes)) {
				this.fileQueue.push(new TsUploadFile(files[i], uUiId, params, url));
			}
		}
		if (this.processingState === ProcessingState.IDLE) {
			this.processQueue();
		} else {
			this.queueSubject.next(this.fileQueue);
		} //In case a update is blocking the state changes from processQueue()
	}

	/**
	 * See {@link MapResource#doDeleteRequest}
	 */
	protected doDeleteRequest(id: number,
		revision: number,
		actionPrefix?: string
	): Observable<VertXSocketMessageResponse>;

	protected doDeleteRequest(id: any, revision: any): Observable<VertXSocketMessageResponse> {
		const prefix = arguments[2];
		if (prefix == null) {
			throw new ReferenceError("[TsFileResource#doDeleteRequest] actionPrefix not defined");
		}

		const action = {
			type: prefix + "/delete",
			payload: {
				id: id,
				rev: revision,
				data: {}
			}
		};
		return observableOf(action).pipe(tap(action => this.store.dispatch(action)),
			switchMap(action => this.socket.emit(action.type, action))
		);
	}

	/**
	 * see {@link MapResource#doFilterRequest
     */
	protected doFilterRequest(filter: QueryFilter,
		actionPrefix?: string
	): Observable<VertXSocketMessageResponse> {
		let action = {
			type: actionPrefix + "/filter",
			payload: filter
		};
		return observableOf(action).pipe(switchMap(action => this.socket.emit(action.type, action)));
	}

	/**
	 * see {@link MapResource#doGetRequest}
	 */
	protected doGetRequest(ids: any[], actionPrefix?: string): Observable<VertXSocketMessageResponse> {
		let action = {
			type: actionPrefix + "/get",
			payload: {
				ids: ids
			}
		};
		return observableOf(action).pipe(switchMap(action => this.socket.emit(action.type, action)));
	}

	/**
	 * Function that is responsible for emptying the queue.
	 * It gets an item out of the queue triggers the {@link uploadFiles} function with the item
	 * and then removes the item out of the queue and calls itself recursively until the queue is empty.
	 */
	private processQueue(): void {
		this.processingState = ProcessingState.PROCESSING;
		this.queueSubject.next(this.fileQueue);
		if (this.fileQueue.length > 0) {
			this.uploadFileToServer(this.fileQueue[0]).then(success => {
				this.fileQueue.shift();
				this.processQueue();
			});
		} else {
			this.processingState = ProcessingState.IDLE;
		}
	}

	/**
	 * Function that creates a request then sends it to the server.
	 * It changes the status of the {@link TsUploadFile#status} to UPLOADING
	 * and updates the progress {@link BehaviorSubject} with the progress.
	 * Returns a promise that resolves when the file is created.
	 */
	private uploadFileToServer(uploadFile: TsUploadFile): Promise<any> {
		// create a data object containing the file and its params.
		const requestData = new FormData();
		requestData.append("file", uploadFile.file, uploadFile.file.name);
		for (const key in uploadFile.params) {
			requestData.append(key, uploadFile.params[key]);
		}

		// create the request that needs to be send to the server.
		const request = new HttpRequest("POST", uploadFile.url, requestData, {
			reportProgress: true,
			withCredentials: true
		});

		// set the state of the file to uploading and then send the request to the server.
		uploadFile.state = TsUploadFileState.UPLOADING;
		return new Promise((resolve, reject) => this.httpClient.request(request).subscribe(event => {
			if (event.type === HttpEventType.UploadProgress) {
				const percentDone = Math.round(100 * event.loaded / event.total);
				uploadFile.progress.next(percentDone);
			} else if (event instanceof HttpResponse) {
				uploadFile.state = TsUploadFileState.DONE;
				console.log("[HTTP-RESPONSE]: ", event.body);
				this.store.dispatch(<Action>event.body);
				resolve(true);
			}
		}, error => {
			this.toastMessageResource.add(`File: ${uploadFile.file.name} is rejected.\nReason: ${error.statusText}`,
				"WARNING"
			);
			uploadFile.state = TsUploadFileState.REJECTED; // Can be used in the future when we want to show the rejected files.
			// processingQueue() does not need to know that the file has been rejected so we resolve the promise so it can continue to upload;
			resolve(true);

		}));
	}

	/**
	 * function to check if a file matches one of the mimeTypes.
	 * If it matches it returns true else it returns false.
	 */
	private isFileValid(file: File, acceptedMimeTypes: string[]) {
		for (const type of acceptedMimeTypes) {
			if (file.type.match(type)) return true;
		}
		this.toastMessageResource.add(`File: ${file.name} is not valid.`, "WARNING");
		return false;
	}

}
