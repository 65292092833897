import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";

import { filter, first } from "rxjs/operators";
import { timeoutWithMessage } from "../timeout-with-message-operator";
import { SocketBackend } from "./backend";

export interface SocketOptions {
	url: string;
	replyAddressKey?: string;
	responseAddressKey?: string;
	timeoutDuration?: number;
}

export interface SocketEvent {
	type: "error" | "open" | "close" | "message";
	data?: Object | any;
}

export enum SocketStates {
	OPENING = 0, OPEN = 1, CLOSING = 2, CLOSED = 3
}

@Injectable()
export class Socket extends Subject<SocketEvent> {
	status: SocketStates = SocketStates.CLOSED;
	protected options: SocketOptions;
	protected socket: SocketBackend;
	private url: string;
	private replyAddressKey: string = "address";
	private responseAddressKey: string = "address";
	private timeoutDuration: number = 10000;
	private statusEvents: BehaviorSubject<SocketStates> = new BehaviorSubject(SocketStates.CLOSED);

	constructor(@Inject("SocketOptions") options, @Inject("socket") socket) {
		super();
		this.options = options;
		this.socket = socket;

		this.url = options.url;
		if (options.replyAddressKey != null) this.replyAddressKey = options.replyAddressKey;
		if (options.responseAddressKey != null) this.responseAddressKey = options.responseAddressKey;
		if (options.timeoutDuration != null) this.timeoutDuration = options.timeoutDuration;

		this.bindOnOpen();
		this.bindOnClose();
		this.bindOnMessage();
		this.bindOnError();
	}

	/**
	 * Function that sets the url on the socket.
	 * If this function is called it generally means that the url has been modified externally
	 * Which means the socket has to already be in a closed state.
	 */
	setUrl(url: string): void {
		this.url = url;
	}

	/**
	 * Add the ability to directly communicate with the socket backend
	 * Important note to make though is that using the backend directly
	 * Can break features within the socket when used incorrectly
	 * So always question if you absolutely have to use this.
	 */
	getBackend(): SocketBackend {
		return this.socket;
	}

	/**
	 * Function with which the WebSocket is opened. This returns a promise to determine if the socket was opened successfully.
	 * The socket is opened based on the configuration supplied to the constructor within the providers / bootstrap method of the @angular application
	 * @returns {Observable}
	 */
	open(): Observable<SocketStates> {
		if (this.status !== SocketStates.CLOSED) {
			throw new Error("Socket is already open, first close it using this.close()");
		}
		this.status = SocketStates.OPENING;
		this.statusEvents.next(SocketStates.OPENING);

		let observable: Observable<SocketStates> = this.statusEvents.pipe(
			filter((state: SocketStates) => state === SocketStates.OPEN),
			timeoutWithMessage(this.timeoutDuration, "[SOCKET-TIMEOUT-MESSAGE] socket was unable to open"),
			first()
		);
		this.socket.open(this.url);
		return observable;
	}

	/**
	 * Function with which the WebSocket is closed.
	 * This purely does some cleaning up and sets the correct {@link Socket.status} once it is done.
	 */
	close(code?: number, reason?: string): void {
		if (this.status === SocketStates.CLOSING || this.status === SocketStates.CLOSED) {
			throw new Error("Socket was already closed, or is closing");
		}
		if (this.status === SocketStates.OPENING) throw new Error("Socket is not open yet");
		this.status = SocketStates.CLOSING;
		this.statusEvents.next(SocketStates.CLOSING);
		this.socket.close(code, reason);
	}

	/**
	 * Function with which a user can send messages over the WebSocket. This function allows the user to wait for the result since it returns a promise
	 * though the implementing code would also be free to directly use the {@link Subject.subscribe()} method if the implementing code has special requirements for it.
	 * The only thing that is handled here is matching of the initially generated requestAddress by listening to the {@link Subject.subscribe} method of this {@link Subject}
	 * and {@link: Observable.filter} filtering that result.
	 * @param payload the content that will be send to the server.
	 * @returns {Observable}
	 */
	send(payload: Object): Observable<Object | SocketEvent> {
		// todo handle offline state, reject with error.code == x  and message === z
		if (this.status === SocketStates.CLOSING || this.status === SocketStates.CLOSED) {
			throw new Error("Socket was already closed, or is closing");
		}
		if (this.status === SocketStates.OPENING) throw new Error("Socket has not finished opening yet");

		let key = this.generateUUID();
		payload[this.replyAddressKey] = key;

		return new Observable<SocketEvent>((subscriber) => {
			let observable: Observable<SocketEvent> = this.pipe(
				filter((message: SocketEvent) => {
					return (message.type === "message" && message.data != null && message.data[this.responseAddressKey] === key);
				}),
				timeoutWithMessage(this.timeoutDuration, "[SOCKET-TIMEOUT-MESSAGE] " + key + " did not" + " provide a response"),
				first()
			);

			observable.subscribe(message => {
				subscriber.next(message);
				subscriber.complete();
			}, error => {
				subscriber.error(error);
			});
			this.socket.send(JSON.stringify(payload));
		});
	}

	/**
	 * Reply to a previous server initiated message. The address must be that messages replyAddress.
	 * @param payload the content that will be send to the server.
	 */
	reply(payload: Object): void {
		// todo handle offline state, reject with error.code == x  and message === z
		if (this.status === SocketStates.CLOSING || this.status === SocketStates.CLOSED) {
			throw new Error("Socket was already closed, or is closing");
		}
		if (this.status === SocketStates.OPENING) throw new Error("Socket has not finished opening yet");
		this.socket.send(JSON.stringify(payload));
	}

	/**
	 * Function with which you can subscribe to a stream of messages. That are pushed to the client by the server (through the {@link SocketBackend.onmessage})
	 * This excludes the close, open and error SocketEvent.
	 */
	messages(): Observable<Object | SocketEvent> {
		return this.pipe(filter(event => event.type === "message"));
	}

	/**
	 * Handle onOpen events from the websocket
	 */
	protected bindOnOpen(): void {
		this.socket.onopen.subscribe(event => {
			this.status = SocketStates.OPEN;
			this.statusEvents.next(SocketStates.OPEN);
			this.next({
				type: "open",
				data: event
			});
		});
	}

	/**
	 * Handle onClose events from the websocket.
	 */
	protected bindOnClose(): void {
		this.socket.onclose.subscribe(event => {
			this.status = SocketStates.CLOSED;
			this.statusEvents.next(SocketStates.CLOSED);
			this.next({
				type: "close",
				data: event
			});
		});
	}

	/**
	 * Handler onMessage events from the websocket.
	 */
	protected bindOnMessage(): void {
		this.socket.onmessage.subscribe(event => {
			let json = JSON.parse(event.data);
			this.next({
				type: "message",
				data: json
			});
		});
	}

	/**
	 * Handle onError events from the Websocket
	 */
	protected bindOnError(): void {
		this.socket.onerror.subscribe(event => {
			this.next({
				type: "error",
				data: event
			});
		});
	}

	/**
	 * Function that generates a unique UID that is used as the unique key/ address
	 * That is send to the server and should be returned by it in the response to a call.
	 */
	protected generateUUID(): string {
		return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (a, b) {
			return b = Math.random() * 16, (a === "y" ? b & 3 | 8 : b | 0).toString(16);
		});
	}
}

