import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { fromJS } from "immutable";
import { Observable } from "rxjs";
import { distinctUntilChanged, filter, map, tap } from "rxjs/operators";
import { VertXSocket } from "../../socket/vertx";
import { TsTranslateMessage, TsTranslator } from "../../translator";
import { TsAppActions } from "../actions";
import { ToastMessage, ToastMessagesStore } from "./model";

@Injectable()
export class ToastMessageResource {
	private toastMessages: Observable<ToastMessagesStore>;

	constructor(private store: Store<{ toastMessages: ToastMessagesStore }>,
		public snackbar: MatSnackBar,
		private socket: VertXSocket,
		private translator: TsTranslator
	) {
		this.toastMessages = store.select("toastMessages");
		this.listenForToastMessageAdded();
		this.listenForNotifications();
	}

	listenForNotifications(): void {
		this.socket.messages()
			.pipe(filter(message => message.payload?.type == TsAppActions.NOTIFICATION_CREATED && message.payload?.data?.renderedMessages?.summary?.showToast === true))
			.subscribe({
				next: (message) => {
					let summary = message.payload.data.renderedMessages.summary;
					if (typeof summary === "string") {
						const summary = fromJS(JSON.parse(message.payload.data.summary));
						if (summary.has("text")) this.add(summary.text);
						return;
					}
					this.add(summary.text);
				},
				error: (error) => {
					console.error(error);
				}
			});
	}

	/**
	 * Function that is responsible for rendering toast messages via the angular material 2 snackBar.
	 */
	listenForToastMessageAdded(): void {
		this.getFirst().pipe(filter(message => {
			return message != null && message.content != null;
		}), distinctUntilChanged(), map(message => {
			if (message.translateMessage == null) return message;
			const translated = this.translator.translateMessage(message.translateMessage);
			message = <ToastMessage>message.setIn(["content"], translated.message);
			if (translated.severity != null) {
				message = <ToastMessage>message.setIn(["type"], translated.severity);
			}
			return message;
		}))
			.subscribe(message => {
				let config = {
					duration: message.duration,
					announcementMessage: message.content,
					panelClass: [message.type]
				};
				const snackbar = this.snackbar.open(message.content,
					message.action != null ? message.action.label : null,
					config
				);
				if (message.action != null && message.action.action != null) {
					snackbar.onAction()
						.subscribe(action => message.action.action(), error => console.error(error));
				}
				snackbar.afterDismissed().pipe(tap(value => console.log(value)))
					.subscribe(closed => {
						this.shift();
					});
			}, error => console.error(error));
	}

	/**
	 * Function that returns the observable
	 * Which will return the ToastMessages Stack / current state.
	 */
	get(): Observable<ToastMessagesStore> {
		return this.toastMessages;
	}

	/**
	 * Function that retrieves the first value that was added to the toast messages.
	 * That is still within the toast messages sub state. If their is not value within the sub state
	 * this observable will not emit any items as it filters out undefined values.
	 */
	getFirst(): Observable<ToastMessage> {
		return this.get().pipe(map(list => list.first()));
	}

	/**
	 * Function that adds a item to the sub-state of toast messages within the store.
	 * This function dispatches an action to the store passing along the provided information.
	 */
	add(content: string,
		type: "DEFAULT" | "WARNING" | "ERROR" | "SUCCESS" = "DEFAULT",
		duration: number = 4000,
		action: { label: string, action: string | Function } = null,
		translateMessage: TsTranslateMessage = null
	): void {
		this.store.dispatch({
			type: TsAppActions.TOAST_ADD,
			payload: {
				content: content,
				type: type,
				duration: duration,
				action: action,
				translateMessage: translateMessage
			}
		});
	}

	/**
	 * Function that removes the first item from the sub state Stack.
	 * This causes the first item to be removed, leading to the next item being returned by the {@link ToastMessageResource.getFirst()} method
	 */
	shift(): void {
		this.store.dispatch({
			type: TsAppActions.TOAST_SHIFT
		});
	}
}
