import { List, Record } from "immutable";
import { TsTranslateMessage } from "../../translator";
import { StoreModel } from "../model";

export interface ToastMessagesStore extends List<ToastMessage> {
}

export const initialToastMessagesStore: ToastMessagesStore = List<ToastMessage>();

export interface ToastMessageModel extends StoreModel {
	content: string;
	duration: number;
	type: "DEFAULT" | "WARNING" | "ERROR" | "SUCCESS";
	action: { label: string, action: (() => void) };
	translateMessage: TsTranslateMessage;
}

export const ToastMessageRecord = Record({
	id: 0,
	rev: 0,
	content: "",
	duration: 10000,
	type: "DEFAULT",
	action: null,
	translateMessage: null
});

export class ToastMessage extends ToastMessageRecord implements ToastMessageModel {
	id: number;
	rev: number;
	content: string;
	duration: number;
	type: "DEFAULT" | "WARNING" | "ERROR" | "SUCCESS";
	action: { label: string, action: (() => void) };
	translateMessage: TsTranslateMessage;

	get displayName() {
		return this.id + " - " + this.type;
	}
}

