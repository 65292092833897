import { OrderedMap } from "immutable";
import { Observable } from "rxjs";
import { StoreModel } from "../../../@twensoc/angular/src/core-module/service/resource";
import { LoggerLocator } from "../../../@twensoc/angular/src/logger-module";

export type AggregateListDataProvider = (ids: number[] | string[]) => Observable<OrderedMap<string | number, StoreModel>>;

export type AggregateFindModelMethod = (id: number | string) => StoreModel | null;

export type AggregateIdSets = { [model: string]: Set<number | string> };

export type AggregateDataStream = Observable<OrderedMap<string | number, StoreModel>[]>

export type AggregateSource = { idField: string, dataProvider: AggregateListDataProvider, merger?: AggregateFindModelMethod }

export interface AggregateSources {
	[model: string]: AggregateSource
}

export class AggregateListSourceConfig {
	private sources: AggregateSources = {};
	private readonly mainSourcePropertyName: string;
	private logger = LoggerLocator.getLogger();

	constructor(mainSourcePropertyName: string) {
		this.mainSourcePropertyName = mainSourcePropertyName;
	}

	addSource(
		propertyName: string,
		dataProvider: AggregateListDataProvider,
		field?: string,
		merger?: AggregateFindModelMethod
	): this {
		if (this.getMainSourcePropertyName() === propertyName) {
			this.logger.fatal("Main source property name cannot be used for another source", {
				class: AggregateListSourceConfig.name,
				mainSourcePropertyName: this.getMainSourcePropertyName(),
				propertyName: propertyName
			});
			throw new Error("Main source property name cannot be used for another source");
		}

		this.sources[propertyName] = {
			idField: (field != null) ? field : propertyName + "Id",
			dataProvider: dataProvider,
			merger: merger
		};
		return this;
	}

	getSources(): AggregateSources {
		return this.sources;
	}

	getSource(propertyName: string): AggregateSource {
		return this.getSources()[propertyName];
	}

	getMainSourcePropertyName(): string {
		return this.mainSourcePropertyName;
	}
}
