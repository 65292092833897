import { Injectable } from "@angular/core";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { List } from "immutable";
import { TsFormArray } from "../../../@twensoc/angular/src/core-module/service/form-array";
import { StoreModel } from "../../../@twensoc/angular/src/core-module/service/resource";
import { LoggerLocator } from "../../../@twensoc/angular/src/logger-module";
import { FormSourceModel } from "../model";

@Injectable()
export class DefaultFormSourceModel implements FormSourceModel {
	protected logger = LoggerLocator.getLogger();
	private model: StoreModel;
	private initialValue: any;

	constructor(private formGroup: FormGroup) {
		this.initialValue = Object.assign({}, this.formGroup.value);
	}

	update(model: StoreModel) {
		this.model = model;
		this.updateForm(model, this.getForm());
	}

	getForm(): FormGroup {
		return this.formGroup;
	}

	reset(): void {
		if (this.model == null) {
			return void this.logger.warning("No model has been set yet", {
				class: DefaultFormSourceModel.name
			});
		}
		this.getForm().reset(this.initialValue);
		this.updateForm(this.model, this.getForm());
	}

	/**
	 * Function which returns the value of the altered {@see AbstractControl}(s)
	 * For new records (id == 0) this method will treat all values as dirty independent of actual control
	 * state.
	 */
	getDirtyControlValues(): { [key: string]: any } {
		let idControl = this.formGroup.get("id");
		return this.getDirtyControlValuesForGroup(this.formGroup, idControl.value === 0);
	}

	ngOnDestroy(): void {
	}

	protected getDirtyControlValuesForGroup(formGroup: FormGroup, isCreate = false) {
		const dirtyValues = {};
		for (let key in formGroup.controls) {
			const control = formGroup.get(key);
			if (control instanceof FormGroup) {
				const subGroupDirtyValues = this.getDirtyControlValuesForGroup(control, isCreate);
				if (Object.keys(subGroupDirtyValues).length <= 0) {
					continue;
				}

				dirtyValues[key] = subGroupDirtyValues;
				continue;
			}

			if (control.dirty === true || isCreate === true) {
				dirtyValues[key] = control.value;
			}
		}
		return dirtyValues;
	}

	protected updateForm(model: StoreModel, group: FormGroup = null, path: any[] = []) {
		// const formGroup = this.getForm();
		for (let key in group.controls) {
			const control = group.controls[key];
			const nPath = path.slice(0);
			nPath.push(key);

			if (control instanceof FormGroup && model.hasIn(nPath)) {
				this.updateFormGroup(model, control, nPath);
				continue;
			}

			if (control instanceof TsFormArray && model.hasIn(nPath)) {
				this.updateTsFormArray(model, control, nPath);
				continue;
			}

			if (control instanceof FormArray && model.hasIn(nPath)) {
				this.updateFormArray(model, control, nPath);
				continue;
			}

			if ((control instanceof FormGroup || control instanceof FormControl || control instanceof FormArray) && model.hasIn(
				nPath) === false) {
				this.logger.debug("No property within the model for the specified path", {
					class: DefaultFormSourceModel.name,
					path: nPath,
					control: control
				});
				continue;
			}

			if (control instanceof FormControl && control.dirty === true) continue;
			if (control instanceof FormControl && model.hasIn(path)) {
				this.logger.debug("Updated the AbstractControl with the new value", {
					class: DefaultFormSourceModel.name,
					path: nPath,
					control: control,
					value: model.getIn(path)
				});
				control.patchValue(model.getIn(nPath));
				continue;
			}

			this.logger.error("Unknown AbstractControl type found for path", {
				class: DefaultFormSourceModel.name,
				path: nPath,
				control: control
			});
		}
	}

	/**
	 * Function that updates a nested {@see: FormGroup} within the {@see getForm}, this is simply done by
	 * calling the {@see updateForm} method again and doens't require any further processing here.
	 */
	protected updateFormGroup(model: StoreModel, control: FormGroup, path: any[]) {
		this.updateForm(model, control, path);
	}

	/**
	 * Function that updates a nested {@see: TsFormArray} within the {@see getForm}, this is done by
	 * calling the {@see updateForm} method repeatedly for every sub {@see FormGroup} within the controls
	 * array of the {@see AbstractControl}.
	 */
	protected updateTsFormArray(model: StoreModel, control: TsFormArray, path: any[]) {
		if (List.isList(model.getIn(path)) === false) {
			return void this.logger.warning("Found entity for path not an instance of List", {
				class: DefaultFormSourceModel.name,
				path: path,
				control: control,
				entity: model.getIn(path)
			});
		}
		model.getIn(path).forEach((value, index) => control.insert(index, value));

		control.controls.forEach((formGroup: FormGroup, index: number) => {
			this.updateForm(model, formGroup, [...path, index]);
		});
	}

	/**
	 * Function that updates a nested {@see: FormArray} within the {@see getForm}, this is done by
	 * calling the {@see updateForm} method repeatedly for every sub {@see FormGroup} within the controls
	 * array of the {@see AbstractControl}.
	 */
	protected updateFormArray(model: StoreModel, control: FormArray, path: any[]) {
		control.controls.forEach((formGroup: FormGroup, index: number) => {
			if (model.hasIn([...path, index]) === false) {
				return void this.logger.warning("No property within the model for the specified path", {
					class: DefaultFormSourceModel.name,
					path: [...path, index],
					control: control,
					entity: model.getIn(path)
				});
			}
			this.updateForm(model, formGroup, [...path, index]);
		});
	}
}
