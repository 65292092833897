import { Inject, Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import {
	DefaultFormSource, FORM_MODEL_FACTORY, FORM_SOURCE, FormModelFactory, FormSourceModel
} from "../form-source";
import { UserFormSourceModel } from "./form-source-model";
import { USER_FORM_GROUP_FACTORY } from "./model";
import { UserResource } from "./resource";

export function userFormSourceFactory(userResource: UserResource, formModelFactory: FormModelFactory) {
	return new UserFormSource(userResource, formModelFactory);
}

@Injectable()
export class UserFormModelFactory implements FormModelFactory {
	create(formGroup: FormGroup): FormSourceModel {
		return new UserFormSourceModel(formGroup);
	}
}

export const USER_FORM_SOURCE_PROVIDER = {
	provide: FORM_SOURCE,
	useFactory: userFormSourceFactory,
	deps: [UserResource, FORM_MODEL_FACTORY]
};

export class UserFormSource extends DefaultFormSource {
	constructor(public resource: UserResource,
		@Inject(FORM_MODEL_FACTORY) formModelFactory: FormModelFactory
	) {
		super(formModelFactory);
		this.setFormGroupFactoryMethod(USER_FORM_GROUP_FACTORY);
	}
}