import { StoreModel } from "./resource";
import { MapResource } from "./resource/map/resource";

export class util {
}

export module Util {

	/**
	 * Function through which a numeric has code is generated from a string
	 * This follows the exact same logic as the Java String.hashCode functionality.
	 * And this code is a copy of: @esmiralha: http://stackoverflow.com/a/7616484/1957541
	 * So all credits go to @esmiralha for creating it.
	 */
	export function hashCode(value: Object): number;
	export function hashCode(value: number): number;
	export function hashCode(value: string): number;
	export function hashCode(value: any): number {
		if (typeof value === "object") value = JSON.stringify(value);
		if (typeof value === "number") value = value.toString();
		if (typeof value !== "string") throw Error("[Util.hashCode] Incompatible type: " + typeof value);

		let hash = 0,
			i,
			chr,
			len;
		if (value.length === 0) return hash;
		for (i = 0, len = value.length; i < len; i++) {
			chr = value.charCodeAt(i);
			hash = ((hash << 5) - hash) + chr;
			hash |= 0; // Convert to 32bit integer
		}
		return hash;
	}

	/**
	 * Typescript TypeGuard to quickly determine if a response object is a success response object.
	 * This is determined by the fact if the response contains a response sub-object.
	 * https://www.typescriptlang.org/docs/handbook/advanced-types.html#user-defined-type-guards
	 *
	 * Example:
	 *
	 * if(Util.isSuccess(resp)) let response = resp.response;
	 */
	export function isSuccess(response: { success: boolean, response: any } | { success: boolean, error: any, request: { type: string, payload: any } }): response is { success: boolean, response: any } {
		return (<{ success: boolean, response: any }>response).response !== undefined;
	}

	/**
	 * Typescript TypeGuard to quickly determine if a response object is an error response object.
	 * This is determined by the fact if the response contains a request and or an error sub-object.
	 * https://www.typescriptlang.org/docs/handbook/advanced-types.html#user-defined-type-guards
	 *
	 * Example:
	 *
	 * if(Util.isError(resp)) let error = resp.error;
	 */
	export function isError(response: { success: boolean, response: any } | { success: boolean, error: any, request: { type: string, payload: any } }): response is { success: boolean, error: any, request: { type: string, payload: any } } {
		return ((<{ success: boolean, error: any, request: { type: string, payload: any } }>response).request !== undefined || (<{ success: boolean, error: any, request: { type: string, payload: any } }>response).error !== undefined);
	}

	/**
	 * Typescript TypeGuard to quickly determine if a response object is an MapResource object.
	 * This is determined by the fact if the response contains a request and or an error sub-object.
	 * https://www.typescriptlang.org/docs/handbook/advanced-types.html#user-defined-type-guards
	 *
	 * Example:
	 *
	 * if(Util.isMapResource(obj)) obj.getByIds();
	 */
	export function isMapResource(resource: any): resource is MapResource<any, any> {
		return (typeof resource["getByIds"] === "function");
	}

	/**
	 * Typescript TypeGuard to quickly determine if a object is an StoreModel object.
	 * This is determined by the fact if the response contains a request and or an error sub-object.
	 * https://www.typescriptlang.org/docs/handbook/advanced-types.html#user-defined-type-guards
	 *
	 * Example:
	 *
	 * if(Util.isModel(obj)) obj.get('dummy');
	 */
	export function isModel<M extends StoreModel>(model: StoreModel): model is M {
		return (typeof model["get"] === "function");
	}

	/**
	 * Function that returns a new client ID, this auto decrements every time this function is called.
	 * The first time this is called it starts at -1 and works it's way down to the lowest possible 32bit integer (not that it will ever be reached :P)
	 */
	export const getClientId = (function () {
		let id = 0;
		return (): number => {
			// id -= 1;
			return --id;
		};
	})();
}
