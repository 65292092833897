import { Logger } from "./logger";

export class LoggerLocator {
	private static logger: Logger;

	static getLogger(): Logger {
		if (this.logger == null) {
			throw new Error(
				"self::logger has not been defined, please provide it before calling getLogger() using LoggerLocator::provide(...)");
		}
		return this.logger;
	}

	static provide(logger: Logger): void {
		this.logger = logger;
	}
}