import { ErrorHandler, ModuleWithProviders, NgModule } from "@angular/core";
import { GlobalErrorHandler } from "./service";

@NgModule({})
export class ErrorHandlingCoreModule {
	static forRoot(): ModuleWithProviders<ErrorHandlingCoreModule> {
		return {
			ngModule: ErrorHandlingCoreModule,
			providers: [
				{
					provide: ErrorHandler,
					useClass: GlobalErrorHandler
				}
			]
		};
	}
}