import { LogHandler } from "../handler";
import { Logger } from "../logger";
import { LogType } from "../type";

export class DefaultLogger implements Logger {
	private handlers: LogHandler[] = [];

	addHandler(handler: LogHandler) {
		this.handlers.push(handler);
	}

	debug(message: string, context?: object): void {
		this.executeHandlers(LogType.DEBUG, message, context);
	}

	trace(message: string, context?: object): void {
		this.executeHandlers(LogType.TRACE, message, context);
	}

	info(message: string, context?: object): void {
		this.executeHandlers(LogType.INFO, message, context);
	}

	warning(message: string, context?: object): void {
		this.executeHandlers(LogType.WARNING, message, context);
	}

	error(message: string, context?: object): void {
		this.executeHandlers(LogType.ERROR, message, context);
	}

	fatal(message: string, context?: object): void {
		this.executeHandlers(LogType.FATAL, message, context);
	}

	private executeHandlers(logType: LogType, message: string, context?: object): void {
		if (this.handlers.length <= 0) {
			console.warn(
				"No handlers were added to the TSLogger, so the log message couldn't be handled",
				message,
				context
			);
		}

		for (let handler of this.handlers) {
			handler.handle(logType, message, context);
		}
	}
}