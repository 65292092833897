import { QueryFilter, QuerySort } from "../../../@twensoc/angular/src/core-module/service/resource";
import { LoggerLocator } from "../../../@twensoc/angular/src/logger-module";
import { Operator } from "../../operator";
import { FieldCast, FieldFilter, ListFilter, Sort, SortDirection } from "../model";

export class DefaultListFilter implements ListFilter {
	private fieldFilters: FieldFilter[] = [];
	private preDeterminedFilter: string = "";
	private sort: Sort = null;
	private offset: number;
	private limit: number = 20;
	private pageNumber: number = 1;
	private search: string = "";
	private operator: Operator;
	private logger = LoggerLocator.getLogger();

	getFieldFilters(): FieldFilter[] {
		return this.fieldFilters;
	}

	getPreDeterminedFilter(): string {
		return this.preDeterminedFilter;
	}

	getSort(): Sort | null {
		return this.sort;
	}

	getLimit(): number {
		return this.limit;
	}

	getPageNumber(): number {
		return this.pageNumber;
	}

	getSearch(): string {
		return this.search;
	}

	getOperator(): Operator {
		return this.operator;
	}

	addFieldFilter(fieldName: string, value: any, cast?: FieldCast): ListFilter {
		const self = Object.create(this);
		self.fieldFilters = self.fieldFilters.slice();
		const fieldFilter = self.fieldFilters.find((filter) => {
			return (filter.field === fieldName && filter.value === value);
		});
		if (fieldFilter != null) return self;

		self.fieldFilters.push({
			field: fieldName,
			value: value,
			cast: cast
		});
		return self;
	}

	/**
	 * Function which builds a {@link QueryFilter} compatible POJO object.
	 * Which is consumed within the {@link ListSource#applyFilter}
	 * @returns {QueryFilter}
	 */
	build(): QueryFilter {
		const filter: QueryFilter = {
			offset: this.offset == null ? ((this.pageNumber * this.limit) - this.limit) : this.offset,
			limit: this.limit,
			search: this.search,
			filters: [],
			sorting: [],
			name: this.preDeterminedFilter
		};

		if (this.sort != null) {
			(<any>filter).sort = [<QuerySort>this.sort];
		}

		// todo once field filters are gone remove them entirely here.
		if (this.fieldFilters.length > 0 && this.operator == null) {
			filter.filters = this.fieldFilters.map(value => {
				const filterObj = {
					field: value.field,
					value: value.value
				};
				if (value.cast != null) {
					filterObj["cast"] = <any>value.cast;
				}
				return filterObj;
			});
		} else if (this.fieldFilters.length <= 0 && this.operator != null) {
			// todo someday remove the <any> cast here as it's a temporary hack for now
			const operatorResult: any = this.operator.build();
			if (operatorResult != null) {
				(<any>filter).filter = operatorResult;
			}
		} else if (this.fieldFilters.length > 0 && this.operator != null) {
			this.logger.fatal("Cannot have both field filters and filter", {
				class: DefaultListFilter.name,
				fieldFilters: this.fieldFilters,
				filter: this.operator
			});
			throw new Error("Cannot have both field filters and a filter");
		}

		return filter;
	}

	setSearch(search: string): ListFilter {
		const self = Object.create(this);
		self.search = search;
		return self;
	}

	clearSearch(): ListFilter {
		const self = Object.create(this);
		self.search = "";
		return self;
	}

	removeFieldFilter(fieldName: string, value: any): ListFilter {
		const self = Object.create(this);
		self.fieldFilters = self.fieldFilters.slice();
		self.fieldFilters = self.fieldFilters.filter((filter) => {
			return (!(filter.field === fieldName && filter.value === value));
		});
		return self;
	}

	clearPreDeterminedFilter(): ListFilter {
		const self = Object.create(this);
		self.preDeterminedFilter = "";
		return self;
	}

	clearSort(): ListFilter {
		const self = Object.create(this);
		self.sort = null;
		return self;
	}

	setOffset(offset: number): ListFilter {
		const self = Object.create(this);
		self.offset = offset;
		return self;
	}

	setLimit(limit: number): ListFilter {
		const self = Object.create(this);
		self.limit = limit;
		return self;
	}

	setPreDeterminedFilter(name: string): ListFilter {
		const self = Object.create(this);
		self.preDeterminedFilter = name;
		return self;
	}

	setPageNumber(pageNumber: number): ListFilter {
		const self = Object.create(this);
		self.pageNumber = pageNumber;
		return self;
	}

	setSort(fieldName: string, direction: SortDirection): ListFilter {
		if (direction === SortDirection.DEFAULT) return this.clearSort();

		const self = Object.create(this);
		self.sort = {
			field: fieldName,
			direction: direction
		};
		return self;
	}

	setOperator(operator: Operator): ListFilter {
		const self = Object.create(this);
		self.operator = operator;
		return self;
	}
}
