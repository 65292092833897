export class Payload {
	public static forData(type: string, id: number, rev: number, data: any): any {
		return {
			type: type,
			payload: {
				id: id,
				rev: rev,
				data: data
			}
		};
	}

	public static actionData(type: string, data: any): any {
		return {
			type: type,
			payload: {
				data: data
			}
		};
	}

	public static forItems(type: string, items: any[]): any {
		return {
			type: type,
			payload: {
				count: items.length,
				items: items
			}
		};
	}

	public static userInterfaceAction(type: string, uUiId: String, data: any) {
		return {
			type: type,
			payload: {
				uUiId: uUiId,
				data: data
			}
		};
	}
}
