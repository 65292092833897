import { Action } from "@ngrx/store";
import { ActionConverter } from "@twensoc/core-module/service/resource/action-converter";
/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 10/06/2016
 * Time: 21:11
 * For Project: telesignaal-app-v2
 */
import { Map } from "immutable";
import { ModelMap } from "./map/model";
import { Model } from "./model";

export class ReducerHandlers {

	/**
	 * Default handler for the RECEIVED action from the server
	 * This can be used within reducers to have a simple way to handle the response from the server.
	 * For example:
	 * return handleReceivedAction<UserStore>(state, action, new User());
	 *
	 * Expected input action format:
	 * {
	 *   type: 'model/received',
	 *   payload: {
	 *		items: [{id: 1, ... },{id: 2, ... }]
	 *	 }
	 * }
	 *
	 * @param {ModelStore} state
	 * @param {Action} action
	 * @param {Record | Map} model
	 * @returns {Stack<T>|Set<T>|Map<K, V>|List<T>}
	 */
	static handleReceivedAction<M extends Model, MS extends ModelMap<M>>(state: MS,
		action: { payload: any, type: string },
		model: any
	): MS {
		return <MS>state.withMutations(state => {
			action.payload.items.forEach((value) => {
				let newModel = model.withMutations(m => {
					for (let p in value) m = m.set(p, value[p]);
				});
				if (newModel.get("id") <= 0) {
					throw new Error("[handleReceivedAction]: item within payload did not contain an Id");
				}
				state.set(newModel.get("id"), newModel);
			});
		});
	}

	/**
	 * Default handler for the CREATE action (adding a new model locally to the store).
	 *
	 * Expected input action format:
	 * {
	 *   type: 'model/create',
	 *   payload: {
	 *     id: -1,
	 *     rev: 0,
	 *     data: {
	 *       foo: 'bar'
	 *     }
	 *	 }
	 * }
	 *
	 * @param {ModelStore} state
	 * @param {Action} action
	 * @param {Record | Map} model
	 * @returns {Stack<T>|Set<T>|Map<K, V>|List<T>}
	 */
	static handleCreateAction<M extends Model, MS extends ModelMap<M>>(state: MS,
		action: { payload: any, type: string },
		model: any
	): MS {
		let id: number = action.payload.id;

		if (state.has(id)) throw new Error("[handleCreateAction]: duplicate id " + id);

		let m = model.withMutations(m => {
			for (let p in action.payload.data) m = m.set(p, action.payload.data[p]);
			m = m.set("rev", 0);
		});
		return <MS>state.set(id, m);
	}

	/**
	 * Default handler for handling a CREATED action.
	 * A CREATED action is dipatched by the server if a new record has been created.
	 * Before adding a record with the payload.data.id, the record with key payload.id is removed,
	 * as it is supposed to be a record created locaaly for temporarily storing a client side record
	 * (? Can be removed if there are no errors to be stored client side ?)
	 *
	 * Expected input action format:
	 * {
	 *   type: 'model/create',
	 *   payload: {
	 *     id: -1,
	 *     rev: 0,
	 *     data: {
	 *       id: 15,
	 * 		 rev: 1,
	 *       foo: 'new record created'
	 *     }
	 *	 }
	 * }
	 * Note that a create action may be received multiple times by the same client,
	 * for example as a reply to a request, on the user/xyz and on the user/0 (admin) channel.
	 * This is why the check on duplicate id's has been removed
	 *
	 * @param {ModelStore} state
	 * @param {Action} action
	 * @param {Record | Map} model
	 * @returns {Stack<T>|Set<T>|Map<K, V>|List<T>}
	 */
	static handleCreatedAction<M extends Model, MS extends ModelMap<M>>(state: MS,
		action: { payload: any, type: string },
		model: any
	): MS {
		return <MS>state.withMutations(state => {
			let payload: any = action.payload;
			let data: any = payload.data;
			let newId: number = data.id;

			model = model.withMutations(m => {
				for (let p in data) m = m.set(p, data[p]);
			});

			return <MS>state.remove(payload.id).set(newId, model);
		});
	}

	/**
	 * Default handler for the UPDATED action.
	 * Updates a record locally if the store already contains (a previous revision) of the model.
	 * The payload.data only contains changed fields.
	 *
	 * Expected input action format:
	 * {
	 *   type: 'model/updated',
	 *   payload: {
	 *     id: 17,
	 *     rev: 2,
	 *     data: {
	 *       id: 17,
	 * 		 rev: 3,
	 *       foo: 'new version'
	 *     }
	 *	 }
	 * }
	 *
	 */
	static handleUpdatedAction<M extends Model, MS extends ModelMap<M>>(state: MS,
		action: { payload: any, type: string },
		model: any
	): MS {
		let id: number = action.payload.id;
		let m: any = state.get(id);
		if (m == null) return state;

		let data: any = action.payload.data;
		m = m.withMutations(m => {
			for (let p in data) m = m.set(p, data[p]);
		});
		return <MS>state.set(id, m);
	}

	static handleBatchUpdatedAction<M extends Model, MS extends ModelMap<M>>(
		state: MS,
		action: { payload: any, type: string },
		actionConverterCfg: ActionConverter.Config
	): MS {
		// ensure that the date's are correctly converted.
		const convertedAction = ActionConverter.adapt({
			type: action.type,
			payload: {
				items: action.payload.data.items
			}
		}, actionConverterCfg);
		const items: { [key: string]: any }[] = convertedAction.payload.items;

		let newState = state.withMutations(mutatedState => {
			for (let item of items) {
				if (state.has(item.id) === false) continue;
				mutatedState.mergeIn([item.id], item); // .merge(item);
			}
		});
		return <MS>newState;
	}

	static handleBatchDeletedAction<M extends Model, MS extends ModelMap<M>>(
		state: MS,
		action: { payload: any, type: string },
		actionConverterCfg: ActionConverter.Config
	): MS {
		const convertedAction = ActionConverter.adapt({
			type: action.type,
			payload: {
				items: action.payload.data.items
			}
		}, actionConverterCfg);
		const items: { [key: string]: any }[] = convertedAction.payload.items;
		let newState = state.withMutations(mutatedState => {
			for (let item of items) {
				if (state.has(item.id)) {
					mutatedState.delete(item.id);
				}
			}
		});
		return <MS>newState;
	}

	/**
	 * Default handler for removing a model locally from the store.
	 *
	 * Expected input action format:
	 * {
	 *   type: 'model/remove',
	 *   payload: {
	 *     id: 15,
	 *     rev: 0
	 *	 }
	 * }
	 *
	 * Possible future calls (not implemented yet :)
	 *
	 * OR
	 * {
	 *   type: 'model/remove',
	 *   payload: {
	 *     ids: [15,16,17]
	 *	 }
	 * }
	 * OR
	 * {
	 *   type: 'model/remove',
	 *   payload: {
	 *     items: [{ id: 15, ...}, { id: 16, ...}, ... ]
	 *	 }
	 * }
	 *
	 * @param {ModelStore} state
	 * @param {Action} action
	 * @returns {Stack<T>|Set<T>|Map<K, V>|List<T>}
	 */
	static handleRemoveLocalAction<M extends Model, MS extends ModelMap<M>>(state: MS,
		action: { payload: any, type: string }
	): MS {
		return <MS>state.delete(action.payload.id);
	}

	/**
	 * Default handler for removing a model from the store.
	 *
	 * Expected input action format:
	 * {
	 *   type: 'model/remove',
	 *   payload: {
	 *     id: 15,
	 *     rev: 0
	 *	 }
	 * }
	 *
	 * Possible future calls (not implemented yet :)
	 *
	 * OR
	 * {
	 *   type: 'model/remove',
	 *   payload: {
	 *     ids: [15,16,17]
	 *	 }
	 * }
	 * OR
	 * {
	 *   type: 'model/remove',
	 *   payload: {
	 *     items: [{ id: 15, ...}, { id: 16, ...}, ... ]
	 *	 }
	 * }
	 *
	 * @param {ModelStore} state
	 * @param {Action} action
	 * @returns {Stack<T>|Set<T>|Map<K, V>|List<T>}
	 */
	static handleDeletedAction<M extends Model, MS extends ModelMap<M>>(state: MS,
		action: { payload: any, type: string }
	): MS {
		return <MS>state.delete(action.payload.id);
	}
}
