export class TsAppActions {
	// userInterface actions
	static UI_SET = "userInterface/set";
	static UI_SETIN = "userInterface/setin";
	static SET_LOGGEDIN = "loggedin/set";
	static ACCOUNT_CHANGED = "account/changed";

	// errors
	static ERROR_REPORT = "error-report";

	// toast message actions
	static TOAST_ADD = "toastMessages/add";
	static TOAST_SHIFT = "toastMessages/shift";

	// notification actions TODO refactor this system and move the notifications actions to their own class (using multi providers)
	static NOTIFICATION_CREATED = "notification/created";

	// file regex https://regex101.com/r/P9oqqy/1
	static FILE_CREATED_REGEX = /^([a-zA-Z0-9].*)-file\/created/;
	static FILE_UPDATED_REGEX = /^([a-zA-Z0-9].*)-file\/updated/;
	static FILE_FILTER_REGEX = /^([a-zA-Z0-9].*)-file\/filter/;
	static FILE_FILTERED_REGEX = /^([a-zA-Z0-9].*)-file\/filtered/;
	static FILE_GET_REGEX = /^([a-zA-Z0-9].*)-file\/get/;
	static FILE_RECEIVED_REGEX = /^([a-zA-Z0-9].*)-file\/received/;
}
