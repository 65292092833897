import { LoggerLocator } from "../../@twensoc/angular/src/logger-module";
import { Map } from "immutable";
import { UserInterfaceActions } from "./action";

export function uiState(state: Map<string, any> = Map([]), action: {
	type: string, payload: {
		key: string, data?: any
	}
}) {
	switch (action.type) {
		case UserInterfaceActions.UPDATE:
			const updatedState = state.set(action.payload.key, action.payload.data);
			LoggerLocator.getLogger().debug("UIState changed", {
				state: updatedState.toJS()
			});
			return updatedState;
		case UserInterfaceActions.DELETE:
			const deletedState = state.filterNot((value, key) => {
				return key.includes(action.payload.key);
			});
			LoggerLocator.getLogger().debug("UIState changed", {
				state: deletedState.toJS()
			});
			return deletedState;
		default:
			return state;
	}
}
