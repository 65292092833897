import { ModuleWithProviders, NgModule } from "@angular/core";
import { AuthenticationGuard } from "./authentication/guard";
import { DetailPageDeactivationGuard } from "./detail-page/guard";
import { LoginGuard } from "./login/guard";

@NgModule({})
export class GuardCoreModule {
	static forRoot(): ModuleWithProviders<GuardCoreModule> {
		return {
			ngModule: GuardCoreModule,
			providers: [LoginGuard, AuthenticationGuard]
		};
	}

	static forFeature(): ModuleWithProviders<GuardCoreModule> {
		return {
			ngModule: GuardCoreModule,
			providers: [DetailPageDeactivationGuard]
		};
	}
}