import { Component, HostBinding, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { delay, filter } from "rxjs/operators";
import { environment } from "../environments/environment";
import { Authentication, Dispatcher } from "./@twensoc/angular/src/core-module/service";
import { ToastMessageResource } from "./@twensoc/angular/src/core-module/service/resource/toast-message";
import { PageTitleService } from "./core/page-title";

@Component({
	selector: "app-component",
	template: `
		<router-outlet></router-outlet>
	`,
	styles: []
})
export class AppComponent implements OnInit {
	constructor(
		private dispatcher: Dispatcher,
		private authentication: Authentication,
		private pageTitleService: PageTitleService,
		private toastMessageResource: ToastMessageResource,
		private router: Router
	) {
	}

	@HostBinding("attr.app-version") get hostBinding() {
		return environment.versionNumber;
	}

	ngOnInit() {
		this.router.events.pipe(
			filter(event => (event instanceof NavigationEnd)),
			delay(10),
			filter(event => document.getElementsByTagName("app-toolbar-component").length > 0 && !document.getElementsByTagName(
				"app-toolbar-component")[0].classList.contains("no-scroll"))
		)
			.subscribe(() => {
				document.getElementsByTagName("app-toolbar-component")[0].scrollIntoView();
			});
	}
}
