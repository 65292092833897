import { Injectable } from "@angular/core";
import {
	ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot
} from "@angular/router";
import { Observable, throwError } from "rxjs";

import { catchError, distinctUntilChanged, filter, first, map, switchMap, tap } from "rxjs/operators";
import { loggedIn } from "../../../@twensoc/angular/src/core-module/service";
import { LoggerLocator } from "../../../@twensoc/angular/src/logger-module";
import { AccountResource } from "../../account";

/**
 * Service that can be used to verify if an user is not logged in and can access the login page.
 *    For permissions the following data is needed in the route configuration:
 *    {
		path: "login",
		component: loginPageComponent,
		canActivate: [LoginGuard],
	}
 */
@Injectable()
export class LoginGuard implements CanActivate {
	private logger = LoggerLocator.getLogger();

	constructor(
		private accountResource: AccountResource,
		private router: Router,
		private activatedRoute: ActivatedRoute
	) {
		this.navigateOnLogin();
	}

	navigateOnLogin(): void {
		this.accountResource.get().pipe(
			filter(account => account != null),
			map(account => account.isLoggedIn),
			distinctUntilChanged(),
			filter(loggedIn => loggedIn === true),
			switchMap(loggedIn => this.activatedRoute.queryParamMap.pipe(first()))
		)
			.subscribe(params => {
				const url = params.get("returnUrl") == null ? "" : params.get("returnUrl");
				this.logger.debug("User has logged in, redirecting him", {
					class: LoginGuard.name,
					params: params,
					url: url
				});

				this.router.navigate([url])
					.catch(error => {
						this.logger.error("Unable to redirect the user", {
							class: LoginGuard.name,
							error: error
						});
					});
			}, error => {
				this.logger.fatal("NavigateOnLogin observable crashed", {
					class: LoginGuard.name,
					error: error
				});
			});
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		return this.accountResource.get().pipe(
			filter(account => account != null && account.loggedIn !== loggedIn.DEFAULT),
			first(),
			map(account => account.loggedIn !== loggedIn.TRUE),
			tap(canActivatePage => {
				if (canActivatePage === true) return;

				this.router.navigate([""]).catch(error => {
					this.logger.error("Unable to redirect the user", {
						class: LoginGuard.name,
						error: error
					});
				});
			}),
			catchError(error => {
				this.logger.fatal("CanActivate observable crashed", {
					class: LoginGuard.name,
					error: error
				});

				return throwError(error);
			})
		);
	}
}
