export enum Permissions {
	// @formatter:off
	VIEW_DASHBOARD = "dashboard/view",
	ACCOUNT_LIST = "account/list",
	ACCOUNT_UPDATE = "account/update",
	ACCOUNT_VIEW = "account/view",
	AVAILABILITY_CREATE = "availability/create",
	AVAILABILITY_UPDATE = "availability/update",
	AVAILABILITY_DELETE = "availability/delete",
	BUCKLE_LIST = "buckle/list",
	BUCKLE_VIEW = "buckle/view",
	BUCKLE_CREATE = "buckle/create",
	BUCKLE_UPDATE = "buckle/update",
	COMPANY_LIST = "company/list",
	COMPANY_VIEW = "company/view",
	COMPANY_CREATE = "company/create",
	COMPANY_UPDATE = "company/update",
	CASE_LIST = "case/list",
	CASE_VIEW = "case/view",
	CASE_CREATE = "case/create",
	CASE_UPDATE = "case/update",
	CALIBER_LIST = "caliber/list",
	CALIBER_VIEW = "caliber/view",
	CALIBER_CREATE = "caliber/create",
	CALIBER_UPDATE = "caliber/update",
	CUSTOMER_VIEW_LEVEL_STATUS = "customer/view-level-status",
	MATERIAL_LIST = "material/list",
	MATERIAL_VIEW = "material/view",
	MATERIAL_CREATE = "material/create",
	MATERIAL_UPDATE = "material/update",
	CASE_MODEL_LIST = "caseModel/list",
	CASE_MODEL_VIEW = "caseModel/view",
	CASE_MODEL_CREATE = "caseModel/create",
	CASE_MODEL_UPDATE = "caseModel/update",
	MODEL_LIST = "caseModel/list",
	MODEL_VIEW = "caseModel/view",
	MODEL_CREATE = "caseModel/create",
	MODEL_UPDATE = "caseModel/update",
	CLOCK_HAND_LIST = "clockHand/list",
	CLOCK_HAND_VIEW = "clockHand/view",
	CLOCK_HAND_CREATE = "clockHand/create",
	CLOCK_HAND_UPDATE = "clockHand/update",
	CLOCK_FACE_LIST = "clockFace/list",
	CLOCK_FACE_VIEW = "clockFace/view",
	CLOCK_FACE_CREATE = "clockFace/create",
	CLOCK_FACE_UPDATE = "clockFace/update",
	CLOCK_FACE_TYPE_LIST = "clockFaceType/list",
	CLOCK_FACE_TYPE_VIEW = "clockFaceType/view",
	CLOCK_FACE_TYPE_CREATE = "clockFaceType/create",
	CLOCK_FACE_TYPE_UPDATE = "clockFaceType/update",
	CLOCK_FACE_MODEL_LIST = "clockFaceModel/list",
	CLOCK_FACE_MODEL_VIEW = "clockFaceModel/view",
	CLOCK_FACE_MODEL_CREATE = "clockFaceModel/create",
	CLOCK_FACE_MODEL_UPDATE = "clockFaceModel/update",
	CUSTOMER_LIST = "customer/list",
	CUSTOMER_VIEW = "customer/view",
	CUSTOMER_CREATE = "customer/create",
	CUSTOMER_UPDATE = "customer/update",
	WATCHBANDCOLOR_LIST = "watchBandColor/list",
	WATCHBANDCOLOR_VIEW = "watchBandColor/view",
	WATCHBANDCOLOR_CREATE = "watchBandColor/create",
	WATCHBANDCOLOR_UPDATE = "watchBandColor/update",
	WATCHSTATUS_LIST = "watchStatus/list",
	WATCHSTATUS_VIEW = "watchStatus/view",
	WATCHSTATUS_CREATE = "watchStatus/create",
	WATCHSTATUS_UPDATE = "watchStatus/update",
	ROTOR_MODEL_LIST = "rotorModel/list",
	ROTOR_MODEL_VIEW = "rotorModel/view",
	ROTOR_MODEL_CREATE = "rotorModel/create",
	ROTOR_MODEL_UPDATE = "rotorModel/update",
	ROTOR_LIST = "rotor/list",
	ROTOR_VIEW = "rotor/view",
	ROTOR_CREATE = "rotor/create",
	ROTOR_UPDATE = "rotor/update",

	FILE_VIEW = "file/view",
	FILE_DELETE = "file/delete",

	KIT_LIST = "kit/list",
	KIT_VIEW = "kit/view",
	KIT_CREATE = "kit/create",
	KIT_UPDATE = "kit/update",
	MOVEMENT_CATEGORY_LIST = "material/list",
	MOVEMENT_CATEGORY_VIEW = "material/view",
	MOVEMENT_CATEGORY_CREATE = "material/create",
	MOVEMENT_CATEGORY_UPDATE = "material/update",
	MOVEMENT_NO_LIST = "movementNo/list",
	MOVEMENT_NO_VIEW = "movementNo/view",
	MOVEMENT_NO_CREATE = "movementNo/create",
	MOVEMENT_NO_UPDATE = "movementNo/update",
	REPAIR_LIST = "repair/list",
	REPAIR_VIEW = "repair/view",
	REPAIR_CREATE = "repair/create",
	REPAIR_UPDATE = "repair/update",
	REPAIRSTATUS_LIST = "repairStatus/list",
	REPAIRSTATUS_VIEW = "repairStatus/view",
	REPAIRSTATUS_CREATE = "repairStatus/create",
	REPAIRSTATUS_UPDATE = "repairStatus/update",
	TASK_CREATE = "task/create",
	TASK_UPDATE = "task/update",
	TASK_DELETE = "task/delete",
	WATCH_BAND_LIST = "watchBand/list",
	WATCH_BAND_VIEW = "watchBand/view",
	WATCH_BAND_CREATE = "watchBand/create",
	WATCH_BAND_UPDATE = "watchBand/update",
	WATCH_LIST = "watch/list",
	WATCH_VIEW = "watch/view",
	WATCH_CREATE = "watch/create",
	WATCH_UPDATE = "watch/update",
	WATCH_VIEW_ADMIN_FILES = "watch/view-admin-files",

	REPAIR_VIEW_ADMIN_FILES = "repair/view-administratie-files",
	REPAIR_UPLOAD_ADMIN_FILES = "repair/upload-administratie-files",
	REPAIR_DELETE_ADMIN_FILES = "repair/delete-administratie-files",

	REPAIR_VIEW_BEFORE_REPAIR_FILES = "repair/view-before-repair-files",
	REPAIR_UPLOAD_BEFORE_REPAIR_FILES = "repair/upload-before-repair-files",
	REPAIR_DELETE_BEFORE_REPAIR_FILES = "repair/delete-before-repair-files",

	REPAIR_VIEW_AFTER_REPAIR_FILES = "repair/view-internal-files",
	REPAIR_UPLOAD_AFTER_REPAIR_FILES = "repair/upload-internal-files",
	REPAIR_DELETE_AFTER_REPAIR_FILES = "repair/delete-internal-files",

	REPAIR_VIEW_CUSTOMER_FILES = "repair/view-customer-files",
	REPAIR_UPLOAD_CUSTOMER_FILES = "repair/upload-customer-files",
	REPAIR_DELETE_CUSTOMER_FILES = "repair/delete-customer-files",

//
//
// 	'repair/upload-administratie-files'),
// (3, 'repair/upload-customer-files'),
// 	(3, 'repair/upload-before-repair-files'),
// 	(3, 'repair/upload-internal-files'),
//


	USER_LIST = "user/list",
	USER_VIEW = "user/view",
	USER_CREATE = "user/create",
	USER_UPDATE = "user/update",
	NOTIFICATION_TEMPLATE_LIST = "notificationTpl/list",
	NOTIFICATION_TEMPLATE_VIEW = "notificationTpl/view",
	NOTIFICATION_TEMPLATE_CREATE = "notificationTpl/create",
	NOTIFICATION_TEMPLATE_UPDATE = "notificationTpl/update",
	SWITCH_USER = "user/simulate-user",
	ORDER_LIST = "order/list",
	ORDER_VIEW = "order/view",
	ORDER_CREATE = "order/create",
	ORDER_UPDATE = "order/update",
	ORDER_DELETE = "order/delete"

// @formatter:on
}
