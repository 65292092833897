import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { CdkVirtualForOf } from "@angular/cdk/scrolling";
import { OrderedMap } from "immutable";
import { BehaviorSubject, ConnectableObservable, Observable, Subscription } from "rxjs";
import {
	debounceTime, distinctUntilChanged, filter, map, publishReplay, startWith, switchMap
} from "rxjs/operators";
import { StoreModel } from "../../../@twensoc/angular/src/core-module/service/resource";
import { MapResource, ModelMap } from "../../../@twensoc/angular/src/core-module/service/resource/map";
import { ListFilter } from "../model";
import { ListSource } from "../source";

export abstract class DefaultListSource<M extends StoreModel> extends DataSource<M> implements ListSource {
	abstract resource: MapResource<M, ModelMap<M>>;
	protected source: ConnectableObservable<M[]>;
	protected filterSubject: BehaviorSubject<ListFilter | null> = new BehaviorSubject<ListFilter>(null);
	protected refreshSubject: BehaviorSubject<void> = new BehaviorSubject<void>(undefined);
	protected connectibleSubscription: Subscription;
	protected countSubscription: Subscription;
	protected countSource: ConnectableObservable<number>;
	protected collectionViewerSubscription: Subscription;
	protected dataStream = new BehaviorSubject<M[]>([]);
	protected cachedModels: M[] = [];

	applyFilter(filter: ListFilter): void {
		this.filterSubject.next(filter);
	}

	changedFilter(): Observable<ListFilter | null> {
		return this.filterSubject.asObservable();
	}

	count(): Observable<number> {
		if (this.countSource == null) this.init();
		return this.countSource;
	}

	connect(collectionViewer?: CollectionViewer): Observable<M[]> {
		console.log("viewer:", collectionViewer);
		if (collectionViewer != null && (collectionViewer instanceof CdkVirtualForOf || typeof collectionViewer === "object")) {
			// infinite scroll
			// when another viewer subscribe reset viewerSubscription otherwise the list won't work.
			if (this.collectionViewerSubscription != null) this.collectionViewerSubscription.unsubscribe();
			this.collectionViewerSubscription = null;
			this.initVirtualScroll(collectionViewer);
			return this.dataStream;
		} else {
			// paginated view
			if (this.source == null) this.init();
			return this.source;
		}
	}

	disconnect(): any {
		if (this.connectibleSubscription != null) this.connectibleSubscription.unsubscribe();
		this.source = null;
		if (this.countSubscription != null) this.countSubscription.unsubscribe();
		this.countSource = null;
		if (this.collectionViewerSubscription == null) this.filterSubject.next(null);
		if (this.collectionViewerSubscription != null) this.collectionViewerSubscription.unsubscribe();
		this.collectionViewerSubscription = null;
	}

	refresh(): void {
		this.refreshSubject.next(undefined);
	}

	getById(id: number | string): Observable<M> {
		return this.resource.getById(<any>id);
	}

	getByIds(ids: number[] | string[]): Observable<OrderedMap<string|number, M>> {
		return this.resource.getByIds(<any>ids);
	}

	/**
	 * The init method where the observable is created that listens for filter changes.
	 * This has a small but important hack however the {@link debounceTime} this call to debounceTime with
	 * value 0 ensures the observable is asynchronous (which it already is) but also ensures (more
	 * importantly) that when the filter is changed multiple times within the same digest cycle a single
	 * request is send to the server.
	 */
	protected init() {
		this.source = <ConnectableObservable<M[]>>this.refreshSubject.pipe(switchMap(v => this.changedFilter()
				.pipe(filter(filter => filter != null))),
			debounceTime(10),
			switchMap(filter => this.filterResource(filter)),
			map(models => models.toArray()),
			publishReplay()
		);
		this.connectibleSubscription = this.source.connect();

		this.countSource = <ConnectableObservable<number>>this.refreshSubject.pipe(switchMap(v => this.changedFilter()
				.pipe(filter(filter => filter != null))),
			debounceTime(10),
			switchMap(filter => this.countResource(filter)),
			publishReplay()
		);
		this.countSubscription = this.countSource.connect();
	}

	protected initVirtualScroll(collectionViewer: CollectionViewer) {
		if (this.source == null) this.init();
		let resetFilter = new BehaviorSubject(null);
		this.countSource.pipe(distinctUntilChanged()).subscribe(count => {
			this.cachedModels = new Array(count);
			this.dataStream.next(this.cachedModels);
			resetFilter.next(null);
		});
		this.collectionViewerSubscription = collectionViewer.viewChange.pipe(debounceTime(500), startWith({
			start: 0,
			end: 20
		}), switchMap(range => this.filterSubject
			.pipe(filter(filter => filter != null), switchMap(value => {
				return resetFilter.pipe(map(value1 => value));
			}), switchMap(filter => this.resource.filter(filter.setOffset(range.start)
				.setLimit(range.end - range.start)
				.build())), map(data => ({
				data: data,
				range: range
			})))))
			.subscribe(data => {
				const currentLength = this.cachedModels.length;
				this.cachedModels.length = 0;
				this.cachedModels.length = currentLength;
				this.cachedModels.splice(data.range.start,
					data.range.end - data.range.start,
					...data.data.toArray()
				);
				this.dataStream.next(this.cachedModels);
			});
	}

	protected filterResource(listFilter: ListFilter): Observable<OrderedMap<string | number, M>> {
		return this.resource.filter(listFilter.build());
	}

	protected countResource(listFilter: ListFilter): Observable<number> {
		return this.resource.count(listFilter.build());
	}
}
