import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { MapResource } from "../../@twensoc/angular/src/core-module/service/resource/map";
import {
	VertXSocket, VertXSocketMessageResponse
} from "../../@twensoc/angular/src/core-module/service/socket";
import { Observable } from "rxjs";
import { filter, first } from "rxjs/operators";
import { AccountActions } from "../account/action"; //Can't import from account index file because of
// circular dependency!
import { User, UserStore } from "./model";

@Injectable()
export class UserResource extends MapResource<User, UserStore> {

	constructor(socket: VertXSocket, store: Store<UserStore>) {
		super(socket, store, "users", "user", new User());
	}

	simulateUser(id): Observable<VertXSocketMessageResponse> {
		const action = {
			type: "user/simulate-user",
			payload: {
				id: id,
				rev: 0,
				data: {}
			}
		};
		return this.socket.emit(action.type, action)
			.pipe(filter((value: any) => value.response.type === AccountActions.LOGIN), first());
	}

	revertUser(): Observable<VertXSocketMessageResponse> {
		const action = {
			type: "user/revert-to-admin",
			payload: {
				id: -1,
				rev: 0,
				data: {}
			}
		};
		return this.socket.emit(action.type, action)
			.pipe(filter(value => value.response.type === AccountActions.LOGIN), first());
	}
}
