import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import * as SockJS from "sockjs-client";

import { AbstractSocketBackend } from "./backend";

@Injectable()
export class SockJSBackend implements AbstractSocketBackend {
	onopen: Subject<Event> = new Subject<Event>();
	onclose: Subject<CloseEvent> = new Subject<CloseEvent>();
	onerror: Subject<Event> = new Subject<Event>();
	onmessage: Subject<MessageEvent> = new Subject<MessageEvent>();
	private socket: any;

	open(url: string, protocols?: string[]) {
		this.socket = new SockJS(url, protocols);
		this.socket.onopen = this.emitOnOpen.bind(this);
		this.socket.onclose = this.emitOnClose.bind(this);
		//this.socket.onerror = this.emitOnError.bind(this); SockJS doesn't have any way to handle errors.
		this.socket.onmessage = this.emitOnMessage.bind(this);
	}

	close(code?: number, reason?: string) {
		this.socket.close();
		this.socket = null;
	}

	send(data: any) {
		let json: any = JSON.parse(data);
		if (json.type == null || json.type !== "ping") {
			console.info("[SOCKET-SEND]\n" + JSON.stringify(json, null, 4));
		}
		this.socket.send(data);
	}

	private emitOnOpen(event: Event) {
		console.info("[SOCKET-OPENED]\n" + JSON.stringify(event, null, 4));
		this.onopen.next(event);
	}

	private emitOnClose(event: CloseEvent) {
		console.warn("[SOCKET-CLOSED]\n" + JSON.stringify(event, null, 4));
		this.onclose.next(event);
	}

	private emitOnError(event: Event) {
		console.error("[SOCKET-ERROR]\n" + JSON.stringify(event, null, 4));
		this.onerror.next(event);
	}

	private emitOnMessage(event: MessageEvent) {
		let pData = JSON.parse(event.data);
		console.info("[SOCKET-MESSAGE]\n" + JSON.stringify(pData, null, 4));
		this.onmessage.next(event);
	}
}