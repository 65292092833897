import { FormControl, FormGroup, Validators } from "@angular/forms";
import { List, Map, Record } from "immutable";
import { StoreModel } from "../../@twensoc/angular/src/core-module/service/resource";
import { StoreModelMap } from "../../@twensoc/angular/src/core-module/service/resource/map";

export type CompanyStore = StoreModelMap<Company>;

export let initialCompanyStore: CompanyStore = <CompanyStore>Map();

export const COMPANY_FORM_GROUP_FACTORY = () => {
	return new FormGroup({
		id: new FormControl(0),
		rev: new FormControl(0),
		parentId: new FormControl(null),
		name: new FormControl("", Validators.required),
		v_street: new FormControl(""),
		v_houseNumber: new FormControl(""),
		v_zipCode: new FormControl(""),
		v_city: new FormControl("", Validators.required),
		phone: new FormControl(""),
		email: new FormControl(""),
		site: new FormControl(""),
		cocNo: new FormControl(""),
		nodeType: new FormControl(""),
		depth: new FormControl(0)
	});
};

export interface CompanyModel extends StoreModel {
	parentId: number | null;
	name: string;
	v_street: string;
	v_houseNumber: string;
	v_zipCode: string;
	v_city: string;
	phone: string;
	email: string;
	site: string;
	cocNo: string;
	nodeType: string;
	depth: number;
}

export class Company extends Record({
	id: 0,
	rev: 0,
	parentId: null,
	name: "",
	v_street: "",
	v_houseNumber: "",
	v_zipCode: "",
	v_city: "",
	p_street: "",
	p_houseNumber: "",
	p_zipCode: "",
	p_city: "",
	phone: "",
	email: "",
	site: "",
	cocNo: "",
	taxNo: "",
	rmaPrefix: "",
	nodeType: "C",
	depth: 0,
	checks: List(),
	peripherals: List()
}) implements CompanyModel {
	id: number;
	rev: number;
	parentId: number | null;
	name: string;
	v_street: string;
	v_houseNumber: string;
	v_zipCode: string;
	v_city: string;
	phone: string;
	email: string;
	site: string;
	cocNo: string;
	nodeType: string;
	depth: number;

	get displayName(): string {
		return this.name;
	}
}
