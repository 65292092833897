import { Injectable } from "@angular/core";
import { DefaultListSource, LIST_SOURCE } from "../list-source";
import { Company } from "./model";
import { CompanyResource } from "./resource";

export function companyListSourceFactory(companyResource: CompanyResource) {
	return new CompanyListSource(companyResource);
}

export const COMPANY_LIST_SOURCE_PROVIDER = {
	provide: LIST_SOURCE,
	useFactory: companyListSourceFactory,
	deps: [CompanyResource]
};

@Injectable()
export class CompanyListSource extends DefaultListSource<Company> {
	constructor(public resource: CompanyResource) {
		super();
	}
}