import { InjectionToken } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { StoreModel } from "../../@twensoc/angular/src/core-module/service/resource";
import { Observable } from "rxjs";

export const FORM_SOURCE = new InjectionToken<FormSource>("FormSource");

export enum FormSourceStatus {
	INITIAL = "INITIAL", LOADING = "LOADING", IDLE = "IDLE", SAVING = "SAVING", DELETING = "DELETING"
}

export interface FormSource {
	getForm(): FormGroup;

	setFormGroupFactoryMethod(formGroupFactoryMethod: () => FormGroup): void;

	resetForm(): void;

	save(): Observable<number | string | null>;

	delete(): Observable<any>;

	load(id?: number | string | undefined): Observable<StoreModel>;

	statusChanged(): Observable<FormSourceStatus>;

	savedId(): Observable<number | string>;
}
