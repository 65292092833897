import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, of, throwError } from "rxjs";

import { catchError, filter, first, merge, switchMap, tap } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { RecordResource } from "../../@twensoc/angular/src/core-module/service/resource/record";
import { VertXSocket } from "../../@twensoc/angular/src/core-module/service/socket";
import { LoggerLocator } from "../../@twensoc/angular/src/logger-module";
import { AccountActions } from "./action";
import { Account, Role } from "./model";

@Injectable()
export class AccountResource extends RecordResource<Account> {
	private logger = LoggerLocator.getLogger();

	constructor(socket: VertXSocket, store: Store<Account>, private http: HttpClient) {
		super(socket, store, "account", "account");
		this.listenToSubStores();
	}

	requestPasswordReset(email: string): Promise<any> {
		const headers = new HttpHeaders().append("Content-Type", "application/json");

		return this.http.post(environment.apiEndpoint + "/reset-password", {email: email}, {headers: headers})
			.pipe(catchError((response: HttpErrorResponse) => {
				this.logger.error("Unable to make request to reset-password", {
					class: AccountResource.name,
					response: response,
					request: {
						url: environment.apiEndpoint + "/reset-password",
						body: {email: email},
						headers: {headers: headers}
					}
				});
				return throwError(response.error);
			}))
			.toPromise();
	}

	resetPassword(token: string, password: string): Promise<any> {
		const headers = new HttpHeaders().append("Content-Type", "application/json");

		return this.http.post(environment.apiEndpoint + "/t/" + token,
			{password: password},
			{headers: headers}
		)
			.pipe(catchError((response: HttpErrorResponse) => {
				this.logger.error("Unable to make request to token handler", {
					class: AccountResource.name,
					response: response,
					request: {
						url: environment.apiEndpoint + "/t/" + token,
						body: {password: "<<NOT_INCLUDED_IN_LOG_FOR_SECURITY_REASONS>>"},
						headers: {headers: headers}
					}
				});
				return throwError(response.error);
			}))
			.toPromise();
	}

	private listenToSubStores() {
		const accountChange = this.onAccountChange();
		const subcription = this.get().pipe(filter(account => account.id !== 0), first())
			.subscribe(account => accountChange(account).toPromise().catch(error => this.logger.error("Error within Promise chain: listenToSubStore",
				{
					class: AccountResource.name,
					error: error
				}
			)));
		subcription.unsubscribe();

		this.socket.messages().pipe(filter(message => message.type === AccountActions.LOGIN),
			switchMap(value => this.get().pipe(filter(account => account.id !== 0), first())),
			switchMap(acc => accountChange(acc))
		)
			.subscribe(messages => {
			}, error => {
				this.logger.error("Error within Observable chain: listenToSubStore", {
					class: AccountResource.name,
					error: error
				});
			});
	}

	/**
	 * If the account changes (including being set initially) one socket channel is opened if the current
	 * account is not Admin (for example "user/3") and two socket channels are opened when the current
	 * account is Admin (for example "user/2" and
	 * "user/0")
	 * @returns {(currentAccount:Account)=>any}
	 */
	private onAccountChange(): (currentAccount: Account) => Observable<any> {
		let previousAccount: Account = null;

		return ((currentAccount: Account) => {
			// inform state about account/changed
			if (previousAccount != null) this.store.dispatch({"type": AccountActions.ACCOUNT_CHANGED});

			// set previousAccount
			previousAccount = currentAccount;
			// open channels
			let channelObservable: Observable<any> = this.socket.bind("user/" + currentAccount.id);

			channelObservable = ((currentAccount.role === Role.ADMIN)
				? channelObservable.pipe(merge(this.socket.bind("user/0"))) : channelObservable);

			channelObservable = channelObservable.pipe(
				tap( v => {
					console.log("CHANNEL MESSAGE FOR "+currentAccount.id+":"+JSON.stringify(v, null, 4));
				}),
				catchError(error => {
					this.logger.error("Error within Observable chain: user channel", {
						class: AccountResource.name,
						error: error
					});
					return of(null);
				}));
			return channelObservable;
		});
	}
}
