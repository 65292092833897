import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { MapResource } from "../../@twensoc/angular/src/core-module/service/resource/map";
import { VertXSocket } from "../../@twensoc/angular/src/core-module/service/socket";
import { Company, CompanyStore } from "./model";

@Injectable()
export class CompanyResource extends MapResource<Company, CompanyStore> {
	constructor(socket: VertXSocket, store: Store<Company>) {
		super(socket, store, "companies", "company", new Company());
	}
}
