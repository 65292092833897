import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { catchError, filter, first, map } from "rxjs/operators";
import { loggedIn } from "../../../@twensoc/angular/src/core-module/service";
import { LoggerLocator } from "../../../@twensoc/angular/src/logger-module";
import { AccountResource } from "../../account";
import { RequirePermission } from "./permission";

/**
 * Service that can be used to verify if an user is logged in and can access a route.
 * For permissions the following data is needed in the route configuration:
 * {
		path: "company",
		component: detailPageComponent,
		canActivate: [AuthenticationGuard],
		data: {permission: "company/get"}
	}
 */
@Injectable()
export class AuthenticationGuard implements CanActivate {
	private currentUrl = "";
	private logger = LoggerLocator.getLogger();

	constructor(private accountResource: AccountResource, private router: Router) {
		this.navigateOnLogout();
	}

	/**
	 * Function that redirects an user to the login page if the user is not logged in.
	 */
	navigateOnLogout(): void {
		this.accountResource.get()
			.pipe(filter(account => account && account.loggedIn && account.loggedIn === loggedIn.FALSE))
			.subscribe(account => {
				this.router.navigate(["/login"], {
					queryParams: {
						returnUrl: this.currentUrl
					}
				}).catch(error => {
					this.logger.error("Error within Observable chain", {
						class: AuthenticationGuard.name,
						error: error
					});
				});
			});
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		this.currentUrl = (state != null && state.url != null) ? state.url : "";
		return this.accountResource.get()
			.pipe(
				filter(account => account != null && account.loggedIn !== loggedIn.DEFAULT),
				first(),
				map(account => {
					if (account.loggedIn !== loggedIn.TRUE) {
						return false;
					}

					const permission: RequirePermission = route && route.data && route.data.permission;
					if (permission == null) {
						return true;
					}

					if (typeof permission === "string") {
						const result = account.hasPermission(permission);
						this.logger.debug("User canActivate information", {
							class: AuthenticationGuard.name,
							result: result
						});
						return result;
					}
					const result = permission.hasPermission.call(permission, route, account);
					this.logger.debug("User canActivate information", {
						class: AuthenticationGuard.name,
						result: result
					});
					return result;
				}),
				catchError(error => {
					this.logger.error("Error within Observable chain", {
						class: AuthenticationGuard.name,
						error: error
					});
					return throwError(error);
				})
			);
	}
}
