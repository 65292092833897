import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Inject, Injectable, InjectionToken } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable, of as observableOf, throwError as observableThrowError } from "rxjs";

import { delay, filter, first, map, retryWhen, switchMap, takeWhile, tap } from "rxjs/operators";
import { Payload } from "./payload";
import { TsAppActions } from "./resource";
import { SocketStates, VertXSocket } from "./socket";
import { timeoutWithMessage } from "./timeout-with-message-operator";

export const loggedIn = {
	DEFAULT: "DEFAULT",
	FALSE: "FALSE",
	TRUE: "TRUE"
};

export interface AuthenticationConfig {
	url: string;
}

@Injectable()
export class Authentication {
	private loginSequenceInFlight: boolean = false;
	private headers: HttpHeaders;

	constructor(
		@Inject(new InjectionToken<AuthenticationConfig>("")) private authenticationConfig: AuthenticationConfig,
		private httpClient: HttpClient,
		private socket: VertXSocket,
		private router: Router,
		private store: Store<any>
	) {
		this.headers = new HttpHeaders();
		this.headers = this.headers.append("Content-Type", "application/json");
	}

	initialize(): void {
		this.loginSequence();
		this.reconnectOnSocketClose();
	}

	/**
	 * Function that is called from within the login form, it passes the users email, password and rememberMe properties.
	 * This function then attempts to log the user in, by calling the login url endpoint specified within the configs.
	 * If the login goes successful it starts up a loginSequence and if this goes successful it will push a true value through the
	 * returned Observable.
	 */
	login(email: string, password: string, rememberMe: boolean): Observable<boolean> {
		return this.httpClient.post(this.authenticationConfig.url + "/login/", JSON.stringify({
			email: email,
			password: password,
			rememberMe: rememberMe
		}), {
			headers: this.headers,
			withCredentials: true
		}).pipe(switchMap(data => {
			this.loginSequence();
			return this.socket.messages().pipe(filter(message => (message.type === "principal/received")))
				.pipe(first(), map(message => (true)), timeoutWithMessage(
					10000,
					"[Authentication.login] No authentication result send from the server to the client"
				));
		}));
	}

	/**
	 * Function that forces the user to be logged out. This results in the user being redirected to /login
	 * On top of the fact that his session refreshToken and accessToken get revoked.
	 */
	logout(): void {
		this.httpClient.get(this.authenticationConfig.url + "/logout/", {
			headers: this.headers,
			withCredentials: true
		}).pipe(map(res => JSON.stringify(res)))
			.subscribe(data => {
				this.reloadWebsite();
			}, (error: HttpErrorResponse) => console.error(error.message));
	}

	/**
	 * The main login sequence is comprised of three methods within this Authentication class.
	 * The {@link Authentication.loginSequence()}, {@link Authentication.reconnectOnSocketClose()} and the {@link Authentication.getAccessToken()} method.
	 * The flow described below is the flow in which the login sequence is handled:
	 *
	 * 1) Open websocket, which in turn validates if the current accessToken is valid
	 *        2a) The current accessToken is valid and the socket could successfully be opened.
	 *        2b) The current accessToken was invalid
	 *            3) Check if the users refreshToken is (still) valid
	 *                3a) The users refreshToken was valid, so the user receives a new accessToken after which he is send back to step 1
	 *                3b) The users refreshToken was invalid, the user is redirected to the login page where he/she will have to login again using an email and password.
	 */
	loginSequence(): void {
		if (this.loginSequenceInFlight !== false) {
			return console.warn("[Authentication.loginSequence] Login sequence already underway");
		}
		if (this.socket.status === SocketStates.OPEN) this.socket.close();
		console.info("[Authentication.loginSequence] Starting a login sequence (1)");
		this.loginSequenceInFlight = true;
		this.socket.messages().pipe(filter(message => (message.type === "principal/received")),
			timeoutWithMessage(
				10000,
				"[Authentication.loginSequence] No authentication result send from the server to the client"
			),
			first()
		)
			.subscribe(message => {
				console.info("[Authentication.loginSequence] User access token was valid (2a)");
			}, error => {
				this.store.dispatch(Payload.actionData(TsAppActions.SET_LOGGEDIN, loggedIn.FALSE));
				console.error(error);
			});
		this.socket.open();
	}

	/**
	 * Function that reloads the website and send the user the /login url
	 * This ensures anything left is removed / cleared like active listeners and open websockets.
	 */
	private reloadWebsite(): void {
		window.location.pathname = "/login";
	}

	/**
	 * Function that handles the socket closing, once a socket closes it tries to reconnect to the socket once more
	 * This reconnecting can only occur a maximum of 10 times within a minute.
	 * See documentation at: {@link Authentication.loginSequence()}
	 */
	private reconnectOnSocketClose(): void {
		let retries = 1;
		this.socket.pipe(filter(event => (event.type === "close")), tap(event => {
			console.debug("[Authentication.reconnectOnSocketClose:filter]", event);
			if (retries >= 10) {
				throw new Error("[Authentication.reconnectOnSocketClose] Maximum iteration count reached");
			}
		}), takeWhile(event => (retries <= 10)), tap(event => {
			console.debug("[Authentication.reconnectOnSocketClose:takeWhile]", event);
		}), map(event => (event.data.body == null) ? event.data : event.data.body), tap(event => {
			console.debug("[Authentication.reconnectOnSocketClose:map]", event);
		}))
			.subscribe(event => {
				console.debug("[Authentication.reconnectOnSocketClose:subscribe]", event);
				this.getAccessToken()
					.subscribe((result) => {
						console.info(
							"[Authentication.reconnectOnSocketClose:getAccessToken] Users refresh token was" + " valid" + " starting a new login sequence (3a)",
							result
						);
						this.loginSequenceInFlight = false;
						this.loginSequence();
					}, error => {
						console.error(
							"[Authentication.reconnectOnSocketClose:getAccessTokenError] Users refresh" + " token was invalid" + " redirecting (3b)",
							error
						);
						this.loginSequenceInFlight = false;
						this.store.dispatch(Payload.actionData(TsAppActions.SET_LOGGEDIN, loggedIn.FALSE));
					});
				retries++;
			}, error => console.error(error));
		this.setInterval(() => {
			retries = 1;
		}, 60000);
	}

	/**
	 * Function with which a new access token is retrieved. By calling the login url, with a refreshToken
	 * This if successful sets the accessToken HTTP only cookie that is required for starting the web socket.
	 */
	private getAccessToken(): Observable<any> {
		return this.httpClient.post(this.authenticationConfig.url + "/login/", JSON.stringify({}), {
			headers: this.headers,
			withCredentials: true
		}).pipe(retryWhen(errors => errors.pipe(switchMap((response: HttpResponse<JSON>) => {
			console.debug("[Authentication.getAccessToken:retryWhen]", response);
			if (response.status === 0) {
				return observableOf(response).pipe(delay(5000));
			}
			return observableThrowError(response);
		}))), tap(res => console.debug("getAccessToken", res)), first());
	}

	/**
	 * Function that wraps the window.setInterval method.
	 * This is due to this a helper function for the tests written so we can mock out the setInterval calls.
	 */
	private setInterval(callback: Function, duration: number) {
		setInterval(() => callback, duration);
	}
}
