import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Map, Record } from "immutable";
import { environment } from "../../../environments/environment";
import { ActionConverter, StoreModel } from "../../@twensoc/angular/src/core-module/service/resource";
import { StoreModelMap } from "../../@twensoc/angular/src/core-module/service/resource/map";

export type UserStore = StoreModelMap<User>;

export const initialUserStore: UserStore = <UserStore>Map();

export const UserConversion: ActionConverter.Config = {
	lastLogin: ActionConverter.stringToDate(new Date())
};

export const USER_FORM_GROUP_FACTORY = () => {
	return new FormGroup({
		id: new FormControl(0),
		rev: new FormControl(0),
		initials: new FormControl(""),
		firstName: new FormControl(""),
		infix: new FormControl(""),
		surname: new FormControl("", Validators.required),
		gender: new FormControl(""),
		email: new FormControl(""),
		phone: new FormControl(""),
		role: new FormControl("", Validators.required),
		authRole: new FormControl(""),
		authRoleId: new FormControl(""),
		active: new FormControl(false),
		parentId: new FormControl(null, Validators.required),
		locale: new FormControl(""),
		customerId: new FormControl(null),
		seqNo: new FormControl(null)
	});
};

export interface UserModel extends StoreModel {
	initials: string;
	firstName: string;
	infix: string;
	surname: string;
	gender: string;
	email: string;
	phone: string;
	authRole: string;
	authRoleId: number;
	role: string; // TODO: Remove
	active: true;
	ppId: number | null;
	parentId: number | null;
	nodeType: string;
	locale: string;
	lastLogin: Date;
	customerId: number;
	seqNo: number;
}

export class User extends Record({
	id: 0,
	rev: 0,
	initials: "",
	firstName: "",
	infix: "",
	surname: "",
	gender: "",
	email: "",
	phone: "",
	authRole: "",
	authRoleId: 0,
	role: "", // TODO: Remove
	active: true,
	ppId: null,
	parentId: null,
	nodeType: "",
	locale: "nl_NL",
	lastLogin: new Date(),
	customerId: null,
	seqNo: null
}) implements UserModel {
	id: number;
	rev: number;
	initials: string;
	firstName: string;
	infix: string;
	surname: string;
	gender: string;
	email: string;
	phone: string;
	authRole: string;
	authRoleId: number;
	role: string; // TODO: Remove
	active: true;
	ppId: number | null;
	parentId: number | null;
	nodeType: string;
	locale: string;
	lastLogin: Date;
	customerId: number;
	seqNo: number;

	get displayName(): string {
		const v = [];
		if (this.firstName != null && this.firstName !== "") v.push(this.firstName);
		if (this.infix != null && this.infix !== "") v.push(this.infix);
		if (this.surname != null && this.surname !== "") v.push(this.surname);
		return v.join(" ");
	};

	get profilePictureSrc(): string {
		if (this.ppId == null || this.ppId === 0) return `/assets/images/profile.jpg`; else return `${environment.apiEndpoint}/user-file/${this.ppId}/100x100`;
	}
}

