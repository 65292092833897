import { ModuleWithProviders, NgModule } from "@angular/core";
import { TsTranslator } from "../../@twensoc/angular/src/core-module/service";
import { AppTranslator } from "./service";

@NgModule()
export class TranslatorCoreModule {
	static forRoot(): ModuleWithProviders<TranslatorCoreModule> {
		return {
			ngModule: TranslatorCoreModule,
			providers: [
				{
					provide: TsTranslator,
					useClass: AppTranslator
				}
			]
		};
	}
}
