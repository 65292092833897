/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 12/13/2016
 * Time: 13:50
 * For Project: angularcore
 */

import { Observable, Operator, OperatorFunction, Subscriber, TeardownLogic } from "rxjs";
import { async } from "rxjs/internal/scheduler/async";
import { SchedulerAction, SchedulerLike } from "rxjs/internal/types";

export function timeoutWithMessage<T>(due: number | Date,
	errorMessage: string,
	scheduler: SchedulerLike = async
): OperatorFunction<T, T> {
	return ((source: Observable<T>) => {
		const absoluteTimeout = isDate(due);
		const waitFor = absoluteTimeout ? (+due - scheduler.now()) : Math.abs(<number>due);
		return source.lift(new TSTimeoutOperator(waitFor,
			absoluteTimeout,
			scheduler,
			new TSTimeoutError(errorMessage)
		));
	});
}

function isDate(value) {
	return value instanceof Date && !isNaN(+value);
}

class TSTimeoutOperator<T> implements Operator<T, T> {
	constructor(private waitFor: number,
		private absoluteTimeout: boolean,
		private scheduler: SchedulerLike,
		private errorInstance: TSTimeoutError
	) {
	}

	call(subscriber: Subscriber<T>, source: any): TeardownLogic {
		return source.subscribe(new TSTimeoutSubscriber<T>(subscriber,
			this.absoluteTimeout,
			this.waitFor,
			this.scheduler,
			this.errorInstance
		));
	}
}

export class TSTimeoutError extends Error {
	constructor(message: string = "Timeout has occurred") {
		const err: any = super(message);
		(<any> this).name = err.name = "TimeoutError";
		(<any> this).stack = err.stack;
		(<any> this).message = err.message;
	}
}

class TSTimeoutSubscriber<T> extends Subscriber<T> {

	private action: SchedulerAction<TSTimeoutSubscriber<T>> = null;

	constructor(destination: Subscriber<T>,
		private absoluteTimeout: boolean,
		private waitFor: number,
		private scheduler: SchedulerLike,
		private errorInstance: TSTimeoutError
	) {
		super(destination);
		this.scheduleTimeout();
	}

	private static dispatchTimeout<T, R>(subscriber: TSTimeoutSubscriber<T>): void {
		subscriber.error(subscriber.errorInstance);
	}

	protected _next(value: T): void {
		if (!this.absoluteTimeout) {
			this.scheduleTimeout();
		}
		super._next(value);
	}

	protected _unsubscribe() {
		this.action = null;
		this.scheduler = null;
		this.errorInstance = null;
	}

	private scheduleTimeout(): void {
		const {action} = this;
		if (action) {
			// Recycle the action if we've already scheduled one. All the production
			// Scheduler Actions mutate their state/delay time and return themeselves.
			// VirtualActions are immutable, so they create and return a clone. In this
			// case, we need to set the action reference to the most recent VirtualAction,
			// to ensure that's the one we clone from next time.
			this.action = (<SchedulerAction<TSTimeoutSubscriber<T>>> action.schedule(this, this.waitFor));
		} else {
			this.add(this.action = (<SchedulerAction<TSTimeoutSubscriber<T>>> this.scheduler.schedule(TSTimeoutSubscriber.dispatchTimeout,
				this.waitFor,
				this
			)));
		}
	}
}