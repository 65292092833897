import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { MatTable } from "@angular/material/table";
import { OrderedMap } from "immutable";
import { BehaviorSubject, combineLatest, ConnectableObservable, Observable, of, Subscription } from "rxjs";

import {
	debounceTime, distinctUntilChanged, filter, map, publishReplay, startWith, switchMap, tap
} from "rxjs/operators";
import { StoreModel } from "../../../@twensoc/angular/src/core-module/service/resource";
import { MapResource, ModelMap } from "../../../@twensoc/angular/src/core-module/service/resource/map";
import { LoggerLocator } from "../../../@twensoc/angular/src/logger-module";
import { ListFilter } from "../model";
import { ListSource } from "../source";
import { AggregateDataStream, AggregateIdSets, AggregateListSourceConfig } from "./model";

export abstract class AggregateListSource<D extends StoreModel, M extends StoreModel> extends DataSource<M>
	implements ListSource {
	abstract resource: MapResource<D, ModelMap<D>>;
	protected source: ConnectableObservable<M[]>;
	protected filterSubject: BehaviorSubject<ListFilter | null> = new BehaviorSubject<ListFilter>(null);
	protected refreshSubject: BehaviorSubject<void> = new BehaviorSubject<void>(undefined);
	protected connectibleSubscription: Subscription;
	protected countSubscription: Subscription;
	protected countSource: ConnectableObservable<number>;
	protected collectionViewerSubscription: Subscription;
	protected dataStream = new BehaviorSubject<D[]>([]);
	protected cachedModels: D[] = [];
	private config: AggregateListSourceConfig;
	private aggregateModelFactory: ((data: object) => M);
	private logger = LoggerLocator.getLogger();

	setConfig(config: AggregateListSourceConfig) {
		this.config = config;
		this.logger.debug("Config has been set", {
			class: AggregateListSource.name,
			config: config
		});
	}

	setAggregateModelProvider(callBack: (data: object) => M): void {
		this.aggregateModelFactory = callBack;
	}

	applyFilter(filter: ListFilter): void {
		this.filterSubject.next(filter);
	}

	changedFilter(): Observable<ListFilter | null> {
		return this.filterSubject.asObservable();
	}

	count(): Observable<number> {
		if (this.countSource == null) this.init();
		return this.countSource;
	}

	connect(collectionViewer?: CollectionViewer): Observable<M[]> {
		if (this.config == null) {
			this.logger.fatal("No config set on AggregateListSource. Please call setConfig() before calling connect()",
				{
					class: AggregateListSource.name
				}
			);
			throw new Error(
				"No config set on AggregateListSource. Please call setConfig() before calling connect()");
		}
		if (collectionViewer != null && !(collectionViewer instanceof MatTable)) {
			// infinite scroll
			// when another viewer subscribe reset viewerSubscription otherwise the list won't work.
			if (this.collectionViewerSubscription != null) this.collectionViewerSubscription.unsubscribe();
			this.collectionViewerSubscription = null;
			this.initVirtualScroll(collectionViewer);
			return <any>this.dataStream;
		} else {
			// paginated view
			if (this.source == null) this.init();
			return this.source;
		}
	}

	disconnect(): any {
		this.connectibleSubscription.unsubscribe();
		this.source = null;
		this.countSubscription.unsubscribe();
		this.countSource = null;
		if (this.collectionViewerSubscription == null) this.filterSubject.next(null);
		if (this.collectionViewerSubscription != null) this.collectionViewerSubscription.unsubscribe();
		this.collectionViewerSubscription = null;
	}

	refresh(): void {
		this.refreshSubject.next(undefined);
	}

	getById(id: number | string): Observable<M> {
		return this.resource.getByIds([<any>id])
			.pipe(tap(models => this.logger.debug("Base model has been loaded", {
					class: AggregateListSource.name,
					models: models
				})),
				switchMap(models => this.createAggregateObservable(models)),
				tap(data => this.logger.debug("Data has been loaded", {
					class: AggregateListSource.name,
					data: data
				})),
				map(data => this.createAggregateOrderedMap(data)),
				tap(models => this.logger.debug("Models have been merged", {
					class: AggregateListSource.name,
					models: models
				})),
				map(models => models.first()),
				tap(model => this.logger.debug("Returning single model", {
					class: AggregateListSource.name,
					model: model
				}))
			);
	}

	getByIds(ids: number[] | string[]): Observable<OrderedMap<string|number, M>> {
		return this.resource.getByIds(<any>ids)
			.pipe(tap(models => this.logger.debug("Base model has been loaded", {
					class: AggregateListSource.name,
					models: models
				})),
				switchMap(models => this.createAggregateObservable(models)),
				tap(data => this.logger.debug("Data has been loaded", {
					class: AggregateListSource.name,
					data: data
				})),
				map(data => this.createAggregateOrderedMap(data)),
				tap(models => this.logger.debug("Models have been merged", {
					class: AggregateListSource.name,
					models: models
				})),
				map(models => models)
			);
	}

	protected createAggregateModel(data: object): M {
		return this.aggregateModelFactory(data);
	}

	protected init() {
		this.source = <ConnectableObservable<M[]>>this.refreshSubject.pipe(switchMap(v => this.changedFilter()
				.pipe(filter(filter => filter != null))),
			debounceTime(10),
			switchMap(filter => this.filterResource(filter)),
			tap(models => this.logger.debug("Base models have been loaded", {
				class: AggregateListSource.name,
				models: models
			})),
			switchMap(models => this.createAggregateObservable(models)),
			tap(data => this.logger.debug("Data has been loaded", {
				class: AggregateListSource.name,
				data: data
			})),
			map(data => this.createAggregateOrderedMap(data)),
			tap(models => this.logger.debug("Models have been merged", {
				class: AggregateListSource.name,
				models: models
			})),
			map(models => models.toArray()),
			publishReplay()
		);
		this.connectibleSubscription = this.source.connect();

		this.countSource = <ConnectableObservable<number>>this.refreshSubject.pipe(switchMap(v => this.changedFilter()
				.pipe(filter(filter => filter != null))),
			debounceTime(10),
			switchMap(filter => this.countResource(filter)),
			publishReplay()
		);
		this.countSubscription = this.countSource.connect();
	}

	/**
	 * Create an object with all the ids from the models defined in the config.
	 * This then can be used to retrieve all models.
	 */
	protected getAggregateIdSets(models: OrderedMap<number | string, D>): AggregateIdSets {
		const aggregateIdSets: AggregateIdSets = {};
		for (let key in this.config.getSources()) {
			aggregateIdSets[key] = new Set();
		}

		models.forEach(model => {
			for (let key in aggregateIdSets) {
				const referenceKey = this.config.getSource(key).idField;
				const referenceValue = model.get(referenceKey);
				if (referenceValue == null) continue;

				// noinspection OverlyComplexBooleanExpressionJS
				if ((typeof referenceValue === "number" && referenceValue !== 0) || (typeof referenceValue === "string" && referenceValue.length > 0)) {
					aggregateIdSets[key].add(model.get(referenceKey));
				}
			}
		});

		this.logger.debug("Determined the Aggregated Ids to be retrieved", {
			class: AggregateListSource.name,
			aggregateIdSets: aggregateIdSets
		});
		return aggregateIdSets;
	}

	protected createAggregateObservable(models: OrderedMap<number | string, D>): AggregateDataStream {
		const aggregateIds = this.getAggregateIdSets(models);
		const aggregateStreams = Object.keys(aggregateIds).map(key => {
			return this.config.getSource(key).dataProvider(Array.from<any>(aggregateIds[key]));
		});
		return combineLatest([of(models), ...aggregateStreams]);
	}

	protected createAggregateOrderedMap(dataStreams: OrderedMap<string | number, StoreModel>[]): OrderedMap<number | string, M> {
		const propertyNames = Object.keys(this.config.getSources());
		return dataStreams[0].reduce<OrderedMap<string | number, M>>((reduction, model, key) => {
			const data = {
				id: model.id,
				rev: model.rev
			};

			data[this.config.getMainSourcePropertyName()] = model;

			propertyNames.forEach((key, index) => {
				const stream = dataStreams[index + 1];
				const propertyName = this.config.getSource(key).idField;
				const value = model.get(propertyName);

				if (this.config.getSource(key).merger != null) {
					const merger = this.config.getSource(key).merger;
					const model = merger(value);
					if (model != null) data[key] = model;
				} else if (stream.has(value) === true) {
					data[key] = stream.get(value);
				}
			});

			return reduction.set(key, this.createAggregateModel(data));
		}, OrderedMap());
	}

	protected initVirtualScroll(collectionViewer: CollectionViewer) {
		if (this.source == null) this.init();
		let resetFilter = new BehaviorSubject(null);
		this.countSource.pipe(distinctUntilChanged()).subscribe(count => {
			this.cachedModels = new Array(count);
			this.dataStream.next(this.cachedModels);
			resetFilter.next(null);
		});
		this.collectionViewerSubscription = collectionViewer.viewChange.pipe(debounceTime(500), startWith({
			start: 0,
			end: 20
		}), switchMap(range => this.filterSubject
			.pipe(filter(filter => filter != null),
				switchMap(value => {
					return resetFilter.pipe(map(value1 => value));
				}),
				switchMap(filter => this.resource.filter(filter.setOffset(range.start)
					.setLimit(range.end - range.start)
					.build())),
				tap(models => this.logger.debug("Base models have been loaded", {
					class: AggregateListSource.name,
					models: models
				})),
				switchMap(models => this.createAggregateObservable(models)),
				tap(data => this.logger.debug("Data has been loaded", {
					class: AggregateListSource.name,
					data: data
				})),
				map(data => this.createAggregateOrderedMap(data)),
				tap(models => this.logger.debug("Models have been merged", {
					class: AggregateListSource.name,
					models: models
				})),
				map(data => ({
					data: data,
					range: range
				}))
			)))
			.subscribe(data => {
				const currentLength = this.cachedModels.length;
				this.cachedModels.length = 0;
				this.cachedModels.length = currentLength;
				this.cachedModels.splice(data.range.start,
					data.range.end - data.range.start,
					...(<any>data.data).toArray()
				);
				this.dataStream.next(this.cachedModels);
			});
	}

	protected filterResource(listFilter: ListFilter): Observable<OrderedMap<string | number, D>> {
		return this.resource.filter(listFilter.build());
	}

	protected countResource(listFilter: ListFilter): Observable<number> {
		return this.resource.count(listFilter.build());
	}
}
