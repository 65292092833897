/**
 * The account of the user logged in lives as a record in the global application state.
 */
import { loggedIn } from "../../@twensoc/angular/src/core-module/service";
import { TsAppActions } from "../../@twensoc/angular/src/core-module/service/resource";
import { UserActions } from "../user";
import { AccountActions } from "./action";
import { Account, initialAccount } from "./model";

export function account(state: Account = initialAccount, action: { type: string, payload: any }): Account {

	switch (action.type) {
		case AccountActions.LOGIN:
			return <Account>state.merge(action.payload.data)
				.set("loggedIn", loggedIn.TRUE);

		case TsAppActions.SET_LOGGEDIN:
			return <Account>state.set("loggedIn", action.payload.data);

		case AccountActions.LOGOUT:
			return new Account({});

		case UserActions.UPDATED:
			if (action.payload.id === state.id) return <Account>state.merge(action.payload.data); else return state;

		default:
			return state;
	}
}
