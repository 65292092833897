import { Inject, Injectable } from "@angular/core";
import { Observable, of as observableOf, throwError as observableThrowError } from "rxjs";

import { catchError, filter, first, map, switchMap, tap } from "rxjs/operators";
import { SocketBackend } from "./backend";
import { Socket, SocketEvent, SocketOptions, SocketStates } from "./socket";

export interface VertXSocketMessage {
	type?: string; //this is located within it after conversion
	payload?: any; //this is located within it after conversion
	address?: string;
	data?: any;
	body?: any;
}

export interface VertXSocketMessageResponse {
	success: boolean,
	response: VertXSocketMessage
}

@Injectable()
export class VertXSocket extends Socket {
	private pingTimeInterval: number;

	constructor(@Inject("SocketOptions") options, @Inject("SocketBackend") socket) {
		super(options, socket);

		this.pipe(filter(event => event.type === "open")).subscribe(() => this.startPing());
		this.pipe(filter(event => event.type === "close")).subscribe(() => this.stopPing());
	}

	/**
	 * Function that starts the pinging to the server.
	 * This is a process that happens every 5 seconds for the entire duration of the application.
	 * The server is called automatically keeping the socket open.
	 */
	startPing() {
		if (this.pingTimeInterval != null) window.clearInterval(this.pingTimeInterval);
		this.pingTimeInterval = window.setInterval(() => {
			this.getBackend().send("{\"type\":\"ping\"}");
		}, 5000);
	}

	/**
	 * Function that stops pinging the server. This clears the interval set by the
	 * {@link VertXSocket.startPing()} method above.
	 */
	stopPing() {
		if (this.pingTimeInterval == null) return;
		window.clearInterval(this.pingTimeInterval);
		this.pingTimeInterval = null;
	}

	/**
	 * Overwritten function that returns SocketMessage instead of SocketEvent object
	 */
	send(payload: Object): Observable<VertXSocketMessage> {
		return super.send(payload).pipe(map((event: SocketEvent) => (event.data.body != null)
			? event.data.body : event.data));
	}

	/**
	 * Overwritten function that returns SocketMessage instead of SocketEvent object
	 * Retain the event.data.replyAddress if available so the client can respond to this message
	 */
	messages(): Observable<VertXSocketMessage> {
		let self: VertXSocket = this;
		return super.messages().pipe(map((event: SocketEvent) => {
			let d: any = event.data;
			let m: any = d.body != null ? d.body : d;
			let replyAddress: string = d.replyAddress;
			if (replyAddress != null) {
				// define a reply function on the message itself
				Object.defineProperty(m, "reply", {
					value: function (message, headers) {
						self.reply(replyAddress, message, headers);
					}
				});
			}
			return m;
		}));
	}

	/**
	 * Replies to a prevously server send message.
	 *
	 * @param {string} replyAddress The address to reply to
	 * @param {Object} body The body of of the message
	 * @param {Object} headers Optional headers
	 */
	reply(replyAddress: string, body: Object = {}, headers: Object = {}): void {
		super.reply({
			address: replyAddress,
			body: body,
			headers: headers,
			type: "send"
		});
	}

	/**
	 * Function with which a user can send messages over the SockJS WebSocket. This function allows the user to wait for the result since it returns a promise
	 * though the implementing code would also be free to directly use the {@link Subject.subscribe()} method if the implementing code has special requirements for it.
	 * The only thing that is handled here is matching of the initially generated requestAddress by listening to the {@link Subject.subscribe} method of this {@link Subject}
	 * and {@link: Observable.filter} filtering that result.
	 *
	 * {
	 * 	"type":"send",
	 * 	"address":"/dossier/list",
	 * 	"headers":{},
	 * 	"body":{
	 * 		"filter":"",
	 * 		"pageNo":1,
	 * 		"totalItems":4294967295,
	 * 		"pageSize":10},
	 * 		"replyAddress":"8310e496-79ad-469d-8147-d732be499e37"
	 *  }
	 *
	 * {
	 * 	"type":"rec",
	 * 	"address":"8310e496-79ad-469d-8147-d732be499e37",
	 * 	"body":{
	 * 		<Whatever the server replies>
	 *  }
	 * }
	 */
	emit(address: string,
		body: Object = {},
		headers: Object = {},
		vertxType = "send"
	): Observable<VertXSocketMessageResponse> {
		let payload = {
			address: address,
			body: body,
			headers: headers,
			type: vertxType
		};
		return this.send(payload).pipe(catchError(socketError => observableThrowError({
			success: false,
			error: socketError,
			request: body
		})), switchMap(response => {
			if (response.hasOwnProperty("failureCode")) {
				return observableThrowError({
					success: false,
					error: response,
					request: body
				});
			}
			return observableOf({
				success: true,
				response: response
			});
		}));
	}

	/**
	 * Function with which a user can start listening on a specific address. This function ensures the user registers himself onto an address, after which
	 * the user receives only the messages which have a type equal to that of the address. Finally when the calling implementation un-subscribes / no subscribers exist anymore it will un-register
	 * the user automatically from the specified address.
	 *
	 * The subscribe() flow is as follows:
	 *
	 * 1) Send a message to the server through the {@link emit} method. Telling the server to register the user to the specified address (parameter).
	 * 2) Start listening to {@link messages} being send of which the {@link VertXSocketMessage#type} matches the address (parameter)
	 *
	 * The unsubscribe() flow is as follows:
	 *
	 * 1) Un-subscribe from the subscription to the {@link messages}
	 * 2) Send a message to the server through the {@link emit} method. Telling the server to unregister the user on the specified address (parameter).
	 */
	bind(address: string, headers: Object = {}) {
		return Observable.create(observer => {
			const subscription = this.emit(address, {}, headers, "register").pipe(
				tap(
					response => console.log(`[VertXSocket#bind] Successfully subscribed to address: ${address}`)),
				switchMap(response => this.messages()),
				filter(message => (message != null && message.type === address))
			)
				.subscribe(observer);

			return () => {
				if (subscription != null) subscription.unsubscribe();
				if (this.status !== SocketStates.OPEN) {
					console.warn(`[VertXSocket#bind] Unable to unsubscribe from address ${address}, socket was already closed`);
				}

				this.emit(address, {}, headers, "unregister").pipe(first())
					.subscribe(
						response => console.log(`[VertXSocket#bind] Successfully unsubscribed from address: ${address}`),
						error => console.error(`[VertXSocket#bind] Unable to unsubscribed from address: ${address}`)
					);
			};
		});
	}

	/**
	 * Function that broadcasts the event to all Vertx Verticles.
	 * That are listening to the specified address.
	 */
	broadcast(address: string,
		body: Object = {},
		headers: Object = {}
	): Observable<VertXSocketMessageResponse> {
		return this.emit(address, body, headers, "publish");
	}

	/**
	 * Function that creates a listener on the server. That causes messages for that listener
	 * To be pushed to the client through the {@link: this.onMessage()} functionality.
	 * @deprecated
	 */
	startListeningTo(address: string, headers: Object = {}): Observable<VertXSocketMessageResponse> {
		return this.emit(address, {}, headers, "register");
	}

	/**
	 * Function that removes the listener on the server. That lead to messages being pushed
	 * through the {@link: this.onMessage()} functionality.
	 * @deprecated
	 */
	stopListeningTo(address: string, headers: Object = {}): Observable<VertXSocketMessageResponse> {
		return this.emit(address, {}, headers, "unregister");
	}
}