import { Inject, Injectable } from "@angular/core";
import { Record } from "immutable";
import { StoreModel } from "../../@twensoc/angular/src/core-module/service/resource";
import { Company, CompanyResource } from "../company";
import {
	AGGREGATE_LIST_CONFIG_FACTORY, AggregateListSource, AggregateListSourceConfigFactory, LIST_SOURCE
} from "../list-source";
import { User } from "./model";
import { UserResource } from "./resource";

export function userListSourceFactory(
	aggregateConfigFactory: AggregateListSourceConfigFactory,
	userResource: UserResource,
	companyResource: CompanyResource
) {
	return new UserListSource(aggregateConfigFactory, userResource, companyResource);
}

export const USER_LIST_SOURCE_PROVIDER = {
	provide: LIST_SOURCE,
	useFactory: userListSourceFactory,
	deps: [
		AGGREGATE_LIST_CONFIG_FACTORY, UserResource, CompanyResource
	]
};

export class UserList extends Record({
	id: 0,
	rev: 0,
	user: new User(),
	company: new Company()
}) implements StoreModel {
	id: number;
	rev: number;
	user: User;
	company: Company;

	get displayName(): string {
		return this.user.displayName;
	};
}

@Injectable()
export class UserListSource extends AggregateListSource<User, UserList> {
	constructor(
		@Inject(AGGREGATE_LIST_CONFIG_FACTORY) configFactory: AggregateListSourceConfigFactory,
		public resource: UserResource,
		companyResource: CompanyResource
	) {
		super();

		const config = configFactory.create("user");
		config.addSource("company", companyResource.getByIds.bind(companyResource), "parentId");
		this.setConfig(config);
		this.setAggregateModelProvider(data => {
			return new UserList(data);
		});
	}
}
