import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRouteSnapshot, ActivationEnd, Router } from "@angular/router";
import { StoreModel } from "../../@twensoc/angular/src/core-module/service/resource";

import { Observable } from "rxjs";
import { filter, first, map } from "rxjs/operators";

@Injectable()
export class PageTitleService {
	constructor(private titleService: Title, private route: Router) {
		this.route.events.pipe(filter(event => (event instanceof ActivationEnd)),
			map((event: ActivationEnd) => event.snapshot)
		)
			.subscribe(snapshot => {
				this.setTitleBasedOnDisplayName(snapshot);
				this.setTitleBasedOnDataTitleProperty(snapshot);
			});
	}

	private setTitleBasedOnDataTitleProperty(snapshot: ActivatedRouteSnapshot) {
		if (snapshot.data.hasOwnProperty("title") === false) return;
		this.titleService.setTitle(snapshot.data.title);
	}

	private setTitleBasedOnDisplayName(snapshot: ActivatedRouteSnapshot) {
		if (snapshot.data.hasOwnProperty("title") === true) return;
		if (snapshot.data.hasOwnProperty("model") === false) return;
		const modelObservable: Observable<StoreModel> = snapshot.data.model;
		modelObservable.pipe(first()).subscribe(value => {
			this.titleService.setTitle(value.displayName);
		});
	}
}
