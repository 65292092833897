import { Action, Store } from "@ngrx/store";
import { OrderedMap } from "immutable";
import { Observable, of as observableOf, throwError as observableThrowError } from "rxjs";
import { catchError, distinctUntilChanged, filter, map, switchMap, tap } from "rxjs/operators";
import { VertXSocket, VertXSocketMessageResponse } from "../../socket";
import { Util } from "../../util";
import { TsAppActions } from "../actions";
import { CollectionResource } from "../collection";
import { Model } from "../model";
import { QueryFilter } from "../query-filter";
import { Resource } from "../resource";
import { ModelMap } from "./model";

/**
 * The base class for the SubStoreResources. that contain an Map of Records.
 * This base class can easily be extended by other classes while having very little required configuration to plug into the backend.
 * The most important part is to define the subStoreName within the ApplicationState and the actionPrefix that is used in the communication to the server.
 * This actionPrefix is the thing that gets prefixed in front of all the Action type's that get dispatched
 * to the server and to the reducers by the implementing class.
 *
 * Example:
 * export class CompanyResource extends MapResource<Company, Companies> {}
 *
 * export class Company extends Model {}
 *
 * export interface Companies extends ModelMap<Company> {}
 */
export abstract class MapResource<M extends Model, S extends ModelMap<M>> extends Resource<S>
	implements CollectionResource<M, S> {

	constructor(protected socket: VertXSocket,
		protected store: Store<any>,
		protected subStoreName: string,
		protected actionPrefix: string,
		protected pristineModel: M
	) {
		super(store, subStoreName, actionPrefix);
	}

	/**
	 * Get the pristine model. That is passed along through the constructor to the resource.
	 * This model is generally speaking just a new instance of a model which implements the
	 * {@link Model} class.
	 */
	getPristineModel(): Observable<M> {
		return observableOf(this.pristineModel);
	}

	/**
	 * Function that retrieves a single Model from within the ModelMap.
	 * This is done based on the id specified. This can either be a string or number.
	 * If none is available this function doesn't return anything except an observable that doesn't complete.
	 *
	 * Example:
	 * <template [model]='resource.getById(1) | async'></template>
	 *
	 * @Component(...)
	 * export class TemplateComponent {
	 *    @Input() model: M;
	 * }
	 *
	 */
	getById(id: string): Observable<M>;
	getById(id: number): Observable<M>;
	getById(id: any): Observable<M> {
		return this.getByIds([id]).pipe(filter(map => map.has(id)),
			map(map => map.get(id)),
			distinctUntilChanged()
		);
	}

	/**
	 * Function that retrieves an OrderedMap sorted by the array of id's that is specified.
	 * These id's can either be an array of numbers or an array of strings. It uses these id's to
	 * determine which id's have to be retrieved from the server (which aren't currently cached client side).
	 * These un-available id's are then retrieved from the server. And put into the state.
	 * Then it returns the Model's in order of the Ids in an OrderedMap.
	 *
	 * Example:
	 * <template [models]='(resource.getByIds([1,2,3,4]) | async).toArray()'></template>
	 *
	 * @Component(...)
	 * export class TemplateComponent {
	 *    @Input() models: M[];
	 * }
	 */
	getByIds(ids: string[]): Observable<OrderedMap<string, M>>;
	getByIds(ids: number[]): Observable<OrderedMap<number, M>>;
	getByIds(ids: any[]): Observable<OrderedMap<any, M>> {
		return this.get().pipe(map(map => ids.filter(id => map.has(id) === false)),
			switchMap(idsToBeRetrieved => {
				if (idsToBeRetrieved.length <= 0) return observableOf({success: true});
				return this.doGetRequest(idsToBeRetrieved);
			}),
			switchMap(result => this.get()),
			map(map => {
				let availableIds = ids.filter(id => (map.has(id) === true));
				return availableIds.reduce((previousValue, id) => previousValue.set(id, map.get(id)),
					OrderedMap()
				);
			}),
			catchError(error => {
				console.error(error);
				if (error.request != null) this.dispatchError(error.request, error.error);
				return observableOf(OrderedMap()); // return empty OrderedMap in case of error
			})
		);
	}

	/**
	 * Function that retrieves an OrderedMap sorted by the array of Id's that get retrieved by calling the server with the specified filter.
	 * This filter is send to the server and results in an array of id's These id's then get send to the {@Link MapResource.getByIds()}
	 * which results in the OrderedMap sorted by those specified id's
	 *
	 * Example:
	 * <template [models]='(resource.filter({search: 'nick'}) | async).toArray()'></template>
	 *
	 * @Component(...)
	 * export class TemplateComponent {
	 *    @Input() models: M[];
	 * }
	 */
	filter(filter: QueryFilter, uUiId?: string): Observable<OrderedMap<number | string, M>> {
		return observableOf(filter).pipe(switchMap(filter => this.doFilterRequest(filter)),
			tap(result => (uUiId != null) ? this.dispatchFilterCount(uUiId + ".count",
				result.response.payload.count
			) : null),
			map(result => result.response.payload.items.map(value => value.id)),
			switchMap(ids => this.getByIds(ids)),
			catchError(error => {
				console.error(error);
				if (error.request != null) this.dispatchError(error.request, error.error);
				return <Observable<OrderedMap<number | string, M>>>observableOf(OrderedMap()); // return empty OrderedMap in case of error
			})
		);
	}

	/**
	 * Function that retrieves the count that get retrieved by calling the server with the
	 * specified filter.
	 * This filter is send to the server and results in a count
	 * Example:
	 * <template [count]='(resource.count({search: 'nick'}) | async)'></template>
	 *
	 * @Component(...)
	 * export class TemplateComponent {
	 *    @Input() count: number;
	 * }
	 */
	count(filter: QueryFilter): Observable<number> {
		return observableOf(filter)
			.pipe(switchMap(filter => this.doCountRequest(filter)),
				map(result => result.response.payload.data.count),
				catchError(error => {
					console.error(error);
					if (error.request != null) this.dispatchError(error.request, error.error);
					return <Observable<number>>observableOf(0); // return empty
				})
			);
	}

	/**
	 * Function through which a model can be created / saved. Based on the id (if this is equal to 0 a new model is created else it's updated)
	 * This function returns a very simple result that indicates of the call save went successful or if it failed.
	 *
	 * Example:
	 * @Component(...)
	 * export class TemplateComponent {
	 * 	constructor(public resource: MapResource) {}
	 *
	 * 	submit() {
	 * 	  this.resource.save(0, 0, {name: 'Nick'}).subscribe(
	 * 		(response: {success: boolean, response: any}) => {
	 *		  //handle success
	 * 	  	}, (error: {success: boolean, error: any, request: Action}) => {
	 * 		  //handle errors
	 * 	  	}
	 * 	  )
	 * 	}
	 * }
	 * Error result: { success: boolean, error: any, request: Action }
	 */
	save(id: string, revision: number, changes: any): Observable<VertXSocketMessageResponse>;

	save(id: number, revision: number, changes: any): Observable<VertXSocketMessageResponse>;

	save(id: any, revision: number, changes: any): Observable<VertXSocketMessageResponse> {
		let observable: Observable<VertXSocketMessageResponse> = (id === 0 || id.length === 0) ? this.create(
			changes) : this.update(id, revision, changes);

		return observable.pipe(catchError(error => {
			console.error(error);
			if (error.request != null) this.dispatchError(error.request, error.error);
			return observableThrowError(error);
		}));
	}

	/**
	 * Function through which a model can be deleted from the local store and from the server.
	 * This function returns a very simple result that indicates of the call save went successful or if it failed.
	 *
	 * Example:
	 * @Component(...)
	 * export class TemplateComponent {
	 * 	constructor(public resource: MapResource) {}
	 *
	 * 	submit() {
	 * 	  this.resource.delete(0, 0).subscribe(
	 * 		(response: {success: boolean, response: any}) => {
	 *		  //handle success
	 * 	  	}, (error: {success: boolean, error: any, request: Action}) => {
	 * 		  //handle errors
	 * 	  	}
	 * 	  )
	 * 	}
	 * }
	 * Error result: { success: boolean, error: any, request: Action }
	 */
	delete(id: string, revision: number): Observable<VertXSocketMessageResponse>;

	delete(id: number, revision: number): Observable<VertXSocketMessageResponse>;

	delete(id: any, revision: number): Observable<VertXSocketMessageResponse> {
		return this.doDeleteRequest(id, revision).pipe(//handle error that occur
			catchError(error => {
				console.error(error);
				if (error.request != null) this.dispatchError(error.request, error.error);
				return observableThrowError(error);
			}));
	}

	/**
	 * Function that handles the getRequest, it makes the call to the server by emitting an Action.
	 * The result of this call is a simple object containing a clearly marked success flag + response or an error and initial request.
	 * This makes it easy for the frontend in case of an error to handle the errors.
	 */
	protected doGetRequest(ids: any[]): Observable<VertXSocketMessageResponse> {
		let action = {
			type: this.actionPrefix + "/get",
			payload: {
				ids: ids
			}
		};
		return observableOf(action).pipe(switchMap(action => this.socket.emit(action.type, action)));
	}

	/**
	 * Function that handles the filterRequest, it makes a call to the server by emitting an Action.
	 * The result of this call is a simple object containing a clearly marked success flag + response or an error and initial request.
	 * This makes it easy for the frontend in case of an error to handle the errors.
	 * Error result: { success: boolean, error: any, request: Action }
	 */
	protected doFilterRequest(filter: QueryFilter): Observable<VertXSocketMessageResponse> {
		let action = {
			type: this.actionPrefix + "/filter",
			payload: filter
		};
		return observableOf(action).pipe(switchMap(action => this.socket.emit(action.type, action)));
	}

	/**
	 * Function that handles the countRequest, it makes a call to the server by emitting an Action.
	 * The result of this call is a simple object containing a clearly marked success flag + response or an error and initial request.
	 * This makes it easy for the frontend in case of an error to handle the errors.
	 * Error result: { success: boolean, error: any, request: Action }
	 */
	protected doCountRequest(filter: QueryFilter): Observable<VertXSocketMessageResponse> {
		let action = {
			type: this.actionPrefix + "/count",
			payload: filter
		};
		return observableOf(action)
			.pipe(switchMap(action => this.socket.emit(action.type, action)));
	}

	/**
	 * @deprecated use {@Link MapResource.count()} instead
	 * Function that dispatches the count to the Store, within the store this can be handled
	 * by for example the UserInterfaceStore to directly update / set the current count.
	 */
	protected dispatchFilterCount(uUiId: string, count: number) {
		this.store.dispatch({
			type: "userInterface/set", // TODO check if there are better alternatives for this string.
			payload: {
				uUiId: uUiId,
				data: count
			}
		});
	}

	/**
	 * Function that handles the create action when the id equals 0. It simply calls the server and passes along the data to the server.
	 * Which results in a simple yet clear result that indicates if the call went successful or if it failed.
	 * This method generally gets called by {@link MapResource.save(0, ...)}
	 * Error result: { success: boolean, error: any, request: Action }
	 */
	protected create(changes: any): Observable<VertXSocketMessageResponse> {
		let action = {
			type: this.actionPrefix + "/create",
			payload: {
				id: Util.getClientId(),
				rev: 0,
				data: changes
			}
		};
		return observableOf(action).pipe(tap(action => this.store.dispatch(action)),
			switchMap(action => this.socket.emit(action.type, action))
		);
	}

	/**
	 * Function that handles the update action when the id is not equal to 0 either greater or smaller. It simply calls the server and passes along the data to the server.
	 * Which results in a simple yet clear result that indicates if the call went successful or if it failed.
	 * This method generally gets called by {@link MapResource.save(1, ...)} / {@link MapResource.save(-1, ...)}
	 * Error result: { success: boolean, error: any, request: Action }
	 */
	protected update(id: any, revision: number, changes: any): Observable<VertXSocketMessageResponse> {
		let action = {
			type: this.actionPrefix + "/update",
			payload: {
				id: id,
				rev: revision,
				data: changes
			}
		};
		return observableOf(action).pipe(tap(action => this.store.dispatch(action)),
			switchMap(action => this.socket.emit(action.type, action))
		);
	}

	/**
	 * Function that handles the delete action. It simply calls the server and passes along the data to the server.
	 * Which results in a simple yet clear result that indicates if the call went successful or if it failed.
	 * This method generally gets called by {@link MapResource.delete(0, ...)}
	 * Error result: { success: boolean, error: any, request: Action }
	 */
	protected doDeleteRequest(id: any, revision: number): Observable<VertXSocketMessageResponse> {
		let action = {
			type: this.actionPrefix + "/delete",
			payload: {
				id: id,
				rev: revision,
				data: {}
			}
		};
		return observableOf(action).pipe(tap(action => this.store.dispatch(action)),
			switchMap(action => this.socket.emit(action.type, action))
		);
	}

	/**
	 * Function that dispatches an event that can be handled by another part of the application.
	 * If a reducer picks up this specific type. For example this could be used to add error messages so that they could be
	 * displayed as toast messages to the user.
	 */
	protected dispatchError(action: Action, response: any) {
		this.store.dispatch({
			type: TsAppActions.ERROR_REPORT,
			payload: {
				send: action,
				response: response
			}
		});
	}
}
