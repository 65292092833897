import { Store } from "@ngrx/store";
import { Observable } from "rxjs";

export abstract class Resource<T> {
	protected subState: Observable<T>;

	constructor(protected store: Store<any>, protected subStoreName: string, protected actionPrefix: string) {
		this.subState = this.store.select(this.subStoreName);
	}

	/**
	 * Function that retrieves the sub-state of the applicationState
	 * This is purely an Observable to which can be subscribed to receive all updates made to this sub-state (specified by the subStoreName in the constructor).
	 *
	 * Example:
	 * <template [data]="(resource.get() | async)"></template>
	 *
	 * @Component(...)
	 * export class TemplateComponent {
	 *    @Input() data: T;
	 * }
	 */
	get(): Observable<T> {
		return this.subState;
	}
}
