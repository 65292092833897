import { ListFilterFactory } from "../factory";
import { ListFilter } from "../model";
import { DefaultListFilter } from "./model";
import { Injectable } from "@angular/core";

@Injectable()
export class DefaultListFilterFactory implements ListFilterFactory {
	create(): ListFilter {
		return new DefaultListFilter();
	}
}