import { Inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from "@angular/router";
import { FORM_SOURCE, FormSource } from "../../form-source";

@Injectable()
export class DetailPageDeactivationGuard implements CanDeactivate<any> {

	constructor(@Inject(FORM_SOURCE) private formSource: FormSource) {
	}

	canDeactivate(component: any,
		currentRoute: ActivatedRouteSnapshot,
		currentState: RouterStateSnapshot
	): boolean {
		if (this.formSource.getForm().dirty === false) {
			return true;
		}

		if (window.confirm("You have unsaved changes, are you sure you want to leave?")) {
			this.formSource.resetForm();
			return true;
		}
		return false;
	}

}